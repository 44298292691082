$mobileSize: 768px;

.global-button {
  background-color: var(--color-blue-950);
  border-radius: 12px;
  border: none;
  color: var(--color-white-1000);
  cursor: pointer;
  font-family: 'SF Pro', serif;
  font-size: 14px;
  letter-spacing: 0.15px;
  min-width: 44px;
  outline: none;
  padding: 8px 16px;
  position: relative;
  transition: 0.5s ease-in-out background-color, 0.5s ease-in-out border;
  white-space: nowrap;

  &__no-transition {
    transition: unset;
  }

  &--p22 {
    padding-left: 22px;
    padding-right: 22px;
  }

  &--h48 {
    font-size: 16px;
    height: 48px;
    min-height: 48px;
  }

  &--h40 {
    height: 40px;
    min-height: 40px;
  }

  &--h32 {
    height: 32px;
    min-height: 32px;
  }

  &--h24 {
    height: 24px;
    min-height: 24px;
  }

  &--h32,
  &--h24 {
    font-size: 12px;
  }

  &:hover {
    background: rgba(var(--color-rgb-blue-900), 1);
  }

  &:disabled {
    background: rgb(var(--color-rgb-black-700)) !important;
    color: var(--color-black-750) !important;
    cursor: not-allowed;

    &:before {
      background-image: url(/assets/plus-button-gray-disable.svg);
    }
  }

  &.blue-border {
    border-color: var(--color-blue-950);
  }

  &--danger {
    background: var(--color-red-900) !important;
    border: unset !important;
    color: var(--color-white-1000) !important;

    &-store {
      background: var(--color-red-650) !important;
      border: unset !important;
      color: var(--color-red-810) !important;
      &:hover {
        color: var(--color-white-1000)!important;
      }
    }

    &-unit-change {
      padding: 8px 16px !important;
    }

    &:not(:disabled):hover {
      background: var(--color-red-1000);
    }

    &:active {
      background: var(--color-red-1000);
    }
  }

  &--danger-outlined {
    background-color: var(--color-white-1000) !important;
    border: 1px solid var(--color-red-900) !important;
    color: var(--color-red-900);

    &:hover {
      border: 1px solid var(--color-red-1000);
    }
  }

  &--plus {
    align-items: center;
    display: flex;
    hyphens: none;
    justify-content: center;
    white-space: nowrap;

    &:before {
      background-image: url(/assets/plus.svg);
      content: '';
      height: 16px;
      margin-right: 8px;
      width: 16px;
    }
  }

  &--green {
    background-color: var(--color-green-1000);

    &:hover {
      background-color: var(--color-green-900);
    }

    &:active {
      background-color: var(--color-green-1000);
    }

    &:disabled {
      background: rgb(var(--color-rgb-black-700));
      color: var(--color-black-750);
      cursor: not-allowed;

      &:before {
        background-image: url(/assets/plus-button-gray-disable.svg);
      }
    }
  }

  &--gray {
    background-color: rgb(var(--color-rgb-black-600));
    color: var(--color-black-1000);

    &:before {
      background-image: url(/assets/plus-button-gray.svg);
    }

    &:hover {
      background-color: rgb(var(--color-rgb-black-700));
    }

    &:active {
      background-color: rgb(var(--color-rgb-black-700));
    }

    &:disabled {
      background-color: rgb(var(--color-rgb-black-600));
      color: rgb(var(--color-rgb-black-800));

      &:before {
        background-image: url(/assets/plus-button-gray-disable.svg);
      }
    }

    &.h-40 {
      height: 40px;
    }

    &.h-32 {
      height: 32px;
    }

    &.newest-gray {
      color: var(--primary-text-color);
      background: var(--color-gray-735);
    }
  }

  &--red {
    background-color: var(--color-red-500);
    color: var(--color-red-780);

    &:hover {
      background-color: var(--color-red-780);
      color: var(--color-white-1000);
    }

    &:active {
      background-color: var(--color-red-780);
    }
  }

  &--transparent {
    background-color: rgb(var(--color-rgb-white-1000));
    color: var(--color-blue-950);
    padding: 7px 15px;
    border: 1px solid var(--color-gray-750);

    &:before {
      background-image: url(/assets/plus-button-transparent.svg);
      background-color: transparent;
    }

    &:hover {
      background-color: rgb(var(--color-rgb-white-1000));
      border: 1px solid var(--color-blue-950);
    }


    &:disabled {
      background-color: var(--color-gray-750);
      color: var(--color-black-750);

      &:before {
        background-image: url(/assets/plus-button-gray-disable.svg);
      }
    }
  }

  &--medium {
    padding: 10px 20px 10px 20px;
    width: 116px;

    &.global-button--plus {
      padding: 10px 10px 10px 20px;
    }

    &:before {
      top: 12px;
      left: 17px;
    }
  }

  &--large {
    padding: 10px 24px 10px 24px;
    width: 116px;

    &.global-button--plus {
      padding: 10px 10px 10px 20px;
    }

    &:before {
      top: 11px;
      left: 17px;
    }
  }

  &--fill {
    width: 100%;
    font-size: 16px;
    height: 48px;
    line-height: normal;
    box-sizing: border-box;
  }

  &--radius-12 {
    border-radius: 12px;
  }

  &--radius-8 {
    border-radius: 8px;
  }
}

.btn-default-size {
  width: 192px;
  height: 40px;
  border-radius: 12px;
}

.button-nav {
  font-family: 'SF Pro', serif;
  height: 40px;
  width: 88px;
  background: unset;
  border-radius: 32px;
  border: unset;
  background: var(--color-gray-735);
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  line-height: 20px;

  &:hover {
    background: var(--color-black-720);
  }

  & .back-icon {
    margin-right: 8px;
  }

  & .next-icon {
    margin-left: 8px;
  }
}

.newest-global-button {
  position: relative;
  padding: 4px 8px;
  width: auto;
  height: 32px;
  border-radius: 8px;

  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;

  cursor: pointer;
  outline: none;

  &-gray {
    background: var(--color-white-1000);
    border: 1px solid var(--color-gray-850);
    color: var(--color-black-997);

    &-plus {
      padding-left: 26px;
      line-height: 22px;

      &::before {
        content: "";
        top: 7px;
        left: 7px;
        position: absolute;
        display: block;
        height: 16px;
        width: 16px;
        background-image: url(/assets/newest_plus_button_gray.svg);
      }
    }
  }
}

.button-black {
  border: 1px solid rgba(var(--color-rgb-white-1000), 0.1);
  border-radius: 4px;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  height: 32px;
  color: var(--color-white-1000);
  background: var(--color-black-960);
  padding: 0 8px;

  &:hover {
    background: rgba(var(--color-rgb-white-1000), 0.1);
    border-color: transparent;
  }
}

@mixin newest-button-main-styles {
  height: 40px;
  padding: 0 16px;
  border-radius: var(--button-border-radius);
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  white-space: nowrap;
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: unset;
}
@mixin button-transition {
  transition: background-color .5s ease-in-out, border .5s ease-in-out;
}

.selected-label-button {
   height: 24px;
   color: var(--color-blue-980);
   border-radius: 16px;
   background: var(--color-white-1000);
   line-height: 24px;
   font-size: 14px;
   border: none;
   display: flex;
   align-items: center;
   justify-content: center;
   padding: 0 8px;
   &::before {
    content: "";
    display: block;
    height: 16px;
    width: 16px;
    margin-right: 4px;
    background-image: url(/assets/selected-label-icon.svg);
  }

  &:disabled {
    cursor: not-allowed !important;
  }
}

.button-red {
  @include newest-button-main-styles();

  background-color: var(--color-red-500)!important;
  color: var(--color-red-780) !important;

  &:hover {
    @include button-transition();
    background-color: var(--color-red-780) !important;
    color: var(--color-white-1000) !important;
  }

  &:active {
    @include button-transition();
    background-color: var(--color-red-780) !important;
  }

  &:disabled {
    @include button-transition();
    background-color: var(--color-red-500) !important;
    color: var(--color-red-950) !important;
    cursor: not-allowed !important;
  }
}


.newest-button {
  @include newest-button-main-styles();

  background-color: var(--accent-color);
  color: var(--button-text-color);

  &.disabled {
    pointer-events: none;
  }

  &-sm {
    font-size: 14px;
    height: 32px;
    border-radius: 16px;
    padding: 0 12px;
  }

  &.button-gray {
    border-radius: var(--button-border-radius);
    border: 1px solid var(--border-color);
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    background: transparent;
    color: var(--primary-text-color);

    &:hover:not(:disabled) {
      background-color: unset;
      color: var(--primary-text-color);
      border: 1px solid var(--color-black-745);
    }

    &:focus:not(:disabled) {
      background-color: unset;
      color: var(--primary-text-color);
    }

    &:disabled {
      color: var(--color-gray-525) !important;
      background: transparent !important;
    }

    &:active:not(:disabled) {
      background-color: unset;
      color: var(--primary-text-color);
      border: 1px solid var(--color-black-745);
    }
  }

  &.button-green {
    background: var(--color-green-200);
    color: var(--color-green-897);

    &.loading {
      background-color: var(--color-green-897) !important;
    }

    &:hover, &:focus {
      background-color: var(--color-green-897) !important;
      color: var(--color-white-1000);
    }

    &:active {
      background-color: var(--color-green-897) !important;
    }

    &:disabled {
      background-color: var(--color-gray-735) !important;
      color: var(--color-gray-525) !important;
    }
  }

  &-spinner {
    &.loading {
      position: relative;
      background-color: var(--color-gray-815) !important;
      color: transparent !important;
      cursor: default;

      &.button-red {
        background-color: var(--color-red-780) !important;
        &::after {
          filter: var(--filter-red-500);
        }
      }

      &::after {
        content: url(/assets/spinner-20-blue.svg);
        margin: -3px 0;
        width: 20px;
        height: 20px;
        box-sizing: border-box;
        background-position: center;
        background-repeat: no-repeat;
        -webkit-animation: spin 0.6s linear infinite;
        animation: spin 0.6s linear infinite;
        position: absolute;
      }
    }
  }

  &-mobile {
    @media (max-width: $mobileSize) {
      width: 32px;
      min-width: 32px !important;
      border-radius: var(--button-border-radius);
      padding: 0 !important;

      &:before {
        margin-right: 0 !important;
      }

      span {
        display: none;
      }

      &-40 {
        width: 40px;
      }
    }
  }

  &-plus:before {
    background-image: url(/assets/plus-blue-16.svg);
    content: "";
    height: 16px;
    margin-right: 8px;
    width: 16px;
  }

  &-plus:hover:before, &-plus:focus-visible:before {
    background-image: url(/assets/plus_newest.svg);
    content: "";
    height: 16px;
    margin-right: 8px;
    width: 16px;
  }

  &-plus:active:before {
    background-image: url(/assets/plus_newest_active.svg);
    content: "";
    height: 16px;
    margin-right: 8px;
    width: 16px;
  }

  &-plus:disabled:before {
    background-image: url(/assets/plus_newest_disabled.svg);
    content: "";
    height: 16px;
    margin-right: 8px;
    width: 16px;
  }

  &:disabled {
    background-color: var(--color-gray-815) !important;
    color: var(--color-blue-860) !important;
    cursor: not-allowed;
  }

  &:hover, &:focus-visible {
    background-color: var(--color-blue-100);
    color: var(--color-blue-990);
  }

  &:active, &.loading {
    background-color: var(--color-gray-815);
    color: var(--button-text-color);
  }

  &--gray {
    background-color: var(--color-gray-735);
    color: var(--primary-text-color);

    &:hover, &:focus-visible {
      background-color: var(--color-gray-805);
      color: var(--primary-text-color);
    }

    &:active {
      background-color: var(--color-gray-455);
      color: var(--primary-text-color);
    }

    &.loading {
      background-color: var(--color-gray-455);
      color: var(--primary-text-color);
      cursor: not-allowed;
    }

    &:disabled {
      background-color: var(--color-gray-735) !important;
      color: var(--color-gray-525) !important;
    }
  }

  &--outline {
    background-color: unset;
    color: var(--primary-text-color);
    border: 1px solid var(--color-gray-805);

    &:hover, &:focus-visible {
      background-color: unset;
      color: var(--primary-text-color);
      border: 1px solid var(--color-black-745);
    }

    &:active {
      background-color: unset;
      color: var(--primary-text-color);
      border: 1px solid var(--color-black-745);
    }

    &.loading {
      background-color: unset;
      color: var(--primary-text-color);
      cursor: not-allowed;
    }

    &:disabled {
      background-color: unset !important;
      color: var(--color-gray-525) !important;
      border: 1px solid var(--color-gray-805);
    }
  }

  &--white {
    background-color: unset;
    color: var(--color-white-1000);
    border: 1px solid rgba(var(--color-rgb-white-1000), 0.24);

    &:focus {
      background-color: unset;
      color: var(--color-white-1000);
    }

    &:hover {
      border: 1px solid rgba(var(--color-rgb-white-1000), 0.64);
      background-color: unset;
      color: var(--color-white-1000);
    }
  }
}

// todo: Urgent implementation - refactor.
.nobel-button {
  font-weight: 600 !important;
  background: var(--nobel-button-main) !important;
  color: var(--color-white-1000) !important;

  &:hover {
    background: var(--nobel-button-hover) !important;
  }
}

.clear-button {
  background: transparent;
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  border-radius: 16px;
  border: 1px solid var(--color-gray-805);
  height: 32px;
  padding: 0 12px;
  white-space: nowrap;

  &:hover, &:focus {
    background: var(--color-black-720);
  }
}

.newest-button-expand {
  .button-expanded-dropdown {
    &__button {
      background: var(--color-gray-815) !important;
      border-radius: 16px !important;
      overflow: hidden;

      &-icon {
        &.expanded {
          background: var(--color-blue-100);
        }

        atl-custom-icon {
          &.expanded {
            transform: unset !important;

            span svg path {
              fill: var(--color-blue-980);
            }
          }
        }
      }
    }

    &__button-icon {
      transition: background-color 0.5s ease-in-out, border 0.5s ease-in-out;
      &:hover {
        background-color: var(--color-blue-100) !important;
        atl-custom-icon {
          span svg path {
            fill: var(--color-blue-990);
          }
        }
      }
    }

    &__button-text:hover {
      transition: background-color 0.5s ease-in-out, border 0.5s ease-in-out;
      &:hover, &:focus {
        background-color: var(--color-blue-100) !important;
        & > div {
          color: var(--color-blue-990);

          atl-custom-icon {
            span svg path {
              stroke: var(--color-blue-990);
            }
          }
        }
      }
    }

    &__button-text {
      padding: 0 !important;

      div {
        width: 100%;
        height: 100%;
        font-weight: 500;
        font-size: 14px;
        color: var(--color-blue-980);
        padding: 0 12px;
        display: flex;
        align-items: center;

        & > span {
          margin-left: 8px;
        }
      }
    }

    &__dropdown-content {
      border: 1px solid var(--color-gray-805) !important;
      box-shadow: 0 16px 24px rgba(var(--color-gray-730), 0.08) !important;
      border-radius: 4px !important;
      z-index: 20 !important;
      padding: 8px;

      button {
        width: 100%;
        border: none;
        outline: none;
        margin-bottom: 8px;
        background-color: var(--color-white-1000);
        padding: 4px 8px;
        border-radius: 4px;
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        text-align: left;
        color: var(--primary-text-color);
        white-space: nowrap;

        &:hover {
          background-color: var(--color-gray-735);
        }

        &:last-child {
          margin: 0;
        }
      }
    }
  }
}

.button-expanded-dropdown__button-text > span {
  width: 100%;
  height: 100%;
  padding: 8px 16px;
  display: flex;
  align-items: center;
}

.offer-button {
  min-width: 74px;
  height: 32px;
  font-weight: 500;
  font-size: 14px;
  padding: 0 8px;

  &::before {
    height: 14px !important;
    width: 14px !important;
    margin-right: 8px;
  }
}

.button-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 32px;
  width: 32px;
  border-radius: 4px;
  box-sizing: border-box;
  padding: 8px;
  cursor: pointer;
  position: relative;

  &:hover, &:focus {
    background: var(--color-black-720);
  }

  &.black:hover {
    img {
      filter: var(--filter-black-960);
    }
  }
}

.button-action-icon {
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid var(--color-gray-805);
  border-radius: 50%;
  cursor: pointer;
 &:hover, &.show {
    background: var(--color-black-720);
    .hover-icon {
      filter: var(--filter-black);
    }
  }
}

.button-accessibility {
  border: none;
}
