.tox.tox-silver-sink.tox-tinymce-aux {
  .tox-pop {
    &:before, &:after {
      content: none;
    }
  }

  .tox-pop__dialog {
    background: var(--color-black-960);
    border: 1px solid rgba(var(--color-rgb-white-1000), 0.16);
    border-radius: 8px;
    padding: 4px;

    .tox-toolbar {
      margin: 0;
    }

    .tox-tbtn {
      width: 24px;
      height: 24px;
      margin: 0;
      padding: 0;
      border-radius: 4px;

      .tox-icon {
        display: list-item;
        list-style: none;
        width: 24px;
        height: 24px;
        background-repeat: no-repeat;
        background-position: center center;

        svg {
          display: none;
        }
      }
    }

    .tox-tbtn--bespoke {
      background-color: transparent;
    }

    .tox-tbtn--enabled, .tox-tbtn--enabled:hover, .tox-tbtn:hover {
      background: rgba(var(--color-rgb-white-1000), 0.1);
    }

    // Bold
    [aria-label='Fet'].tox-tbtn .tox-icon,
    [aria-label='Bold'].tox-tbtn .tox-icon {
      background-image: url(/assets/editor-bold-white.svg);
    }

    // Italic
    [aria-label='Kursiv'].tox-tbtn .tox-icon,
    [aria-label='Italic'].tox-tbtn .tox-icon {
      background-image: url(/assets/editor-italic-white.svg);
    }

    // Underline
    [aria-label='Understreking'].tox-tbtn .tox-icon,
    [aria-label='Understruken'].tox-tbtn .tox-icon,
    [aria-label='Underline'].tox-tbtn .tox-icon {
      background-image: url(/assets/editor-underline-white.svg);
    }

    //Font sizes
    [aria-label='Font sizes'].tox-tbtn {
      background-image: url(/assets/font-size-white.svg);
      background-repeat: no-repeat;
      background-position: center;
      .tox-tbtn__select-chevron, .tox-tbtn__select-label {
        display: none;
      }
    }

    // Numbered list
    [aria-label='Nummerliste'].tox-tbtn .tox-icon,
    [aria-label='Nummerlista'].tox-tbtn .tox-icon,
    [aria-label='Numbered list'].tox-tbtn .tox-icon {
      background-image: url(/assets/editor-list-numbers-white.svg);
    }

    // Bullet list
    [aria-label='Punktliste'].tox-tbtn .tox-icon,
    [aria-label='Punktlista'].tox-tbtn .tox-icon,
    [aria-label='Bullet list'].tox-tbtn .tox-icon {
      background-image: url(/assets/editor-list-dashes-white.svg);
    }

    // Insert/edit link
    [aria-label='Sett inn / rediger kobling'].tox-tbtn .tox-icon,
    [aria-label='Insert/edit link'].tox-tbtn .tox-icon,
    [aria-label='Infoga/redigera länk'].tox-tbtn .tox-icon {
      background-image: url(/assets/editor-link-white.svg);
    }

    // Insert/edit image
    [aria-label='Sett inn / rediger bilde'].tox-tbtn .tox-icon,
    [aria-label='Infoga/redigera bild'].tox-tbtn .tox-icon,
    [aria-label='Insert/edit image'].tox-tbtn .tox-icon {
      background-image: url(/assets/editor-image-white.svg);
    }

    //Align left
    [aria-label='Vänsterjustera'].tox-tbtn .tox-icon,
    [aria-label='Venstrejuster'].tox-tbtn .tox-icon,
    [aria-label='Align left'].tox-tbtn .tox-icon {
      background-image: url(/assets/editor-align-left-white.svg);
    }

    //Align center
    [aria-label='Centrera'].tox-tbtn .tox-icon,
    [aria-label='Midtstill'].tox-tbtn .tox-icon,
    [aria-label='Align center'].tox-tbtn .tox-icon {
      background-image: url(/assets/editor-align-center-white.svg);
    }

    //Align right
    [aria-label='Högerjustera'].tox-tbtn .tox-icon,
    [aria-label='Høyrejuster'].tox-tbtn .tox-icon,
    [aria-label='Align right'].tox-tbtn .tox-icon {
      background-image: url(/assets/editor-align-right-white.svg);
    }

    //Align justify
    [aria-label='Verifiera'].tox-tbtn .tox-icon,
    [aria-label='Blokkjuster'].tox-tbtn .tox-icon,
    [aria-label='Justify'].tox-tbtn .tox-icon {
      background-image: url(/assets/editor-align-justify-white.svg);
    }

    // Heading 1
    [aria-label='Overskrift 1'].tox-tbtn,
    [aria-label='Rubrik 1'].tox-tbtn,
    [aria-label='Heading 1'].tox-tbtn {
      background-image: url(/assets/editor-h1-white.svg);
      background-repeat: no-repeat;
      background-position: center center;
      width: 34px;
      padding: 0 !important;

      span {
        display: none;
      }
    }

    // Heading 2
    [aria-label='Overskrift 2'].tox-tbtn,
    [aria-label='Rubrik 2'].tox-tbtn,
    [aria-label='Heading 2'].tox-tbtn {
      background-image: url(/assets/editor-h2-white.svg);
      background-repeat: no-repeat;
      background-position: center center;
      width: 34px;
      padding: 0 !important;

      span {
        display: none;
      }
    }

    // Heading 3
    [aria-label='Overskrift 3'].tox-tbtn,
    [aria-label='Rubrik 3'].tox-tbtn,
    [aria-label='Heading 3'].tox-tbtn {
      background-image: url(/assets/editor-h3-white.svg);
      background-repeat: no-repeat;
      background-position: center center;
      width: 34px;
      padding: 0 !important;

      span {
        display: none;
      }
    }
  }

  .tox-dialog {
    font-family: "SF Pro", serif !important;
    border-radius: 8px !important;
    border: none;

    &__header {
      .tox-button.tox-button--icon {
        &:hover {
          background: none;
          border-color: transparent;
        }
        .tox-icon {
          display: block;
          background-image: url(/assets/close-icon.svg);
          background-repeat: no-repeat;
          background-position: center center;
          width: 16px;
          height: 16px;

          svg {
            display: none;
          }
        }
      }
    }

    &__title {
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      font-family: "SF Pro", serif;
    }

    .tox-form__group {
      .tox-label {
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        color: var(--color-gray-890);
      }

      .tox-textfield {
        font-family: "SF Pro", serif;
        height: 32px;
        padding: 5px 0 5px 8px;
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        color: var(--primary-text-color);
        border-radius: 4px !important;
        border: 1px solid var(--color-gray-525);

        &:focus {
          box-shadow: 0 6px 8px -4px rgba(var(--color-rgb-black-840), 0.2);
        }
      }
    }

    .tox-dialog__footer {
      &-end {
        .tox-button {
          font-family: "SF Pro", serif;
          height: 40px;
          padding: 0 16px;
          background-color: var(--color-gray-888);
          border-radius: 32px;
          font-weight: 500;
          font-size: 16px;
          line-height: 24px;
          white-space: nowrap;
          color: var(--color-blue-980);
          border: none;
          cursor: pointer;
          display: flex;
          justify-content: center;
          align-items: center;
          transition: background-color .5s ease-in-out, border .5s ease-in-out;
          margin-left: 16px;

          &:hover {
            background-color: var(--color-blue-100);
            color: var(--color-blue-990);
          }

          &:active {
            background-color: var(--color-gray-815);
            color: var(--color-blue-980);
          }

          &.tox-button--secondary {
            color: var(--primary-text-color);
            background: var(--color-gray-735);

            &:hover, &:active {
              background: rgb(var(--color-rgb-black-700));
            }
          }
        }
      }
    }
  }
}

.tox-pop.tox-pop--bottom::before, .tox-pop.tox-pop--bottom::after {
  border-color: var(--color-black-960) transparent transparent transparent !important;
}

.tox-pop.tox-pop--top::before, .tox-pop.tox-pop--top::after {
  border-color: transparent transparent var(--color-black-960) transparent !important;
}

.tox-pop.tox-pop--left::before, .tox-pop.tox-pop--left::after {
  border-color: transparent var(--color-black-960) transparent transparent !important;
}

.tox-menu.tox-collection--list {
  .tox-collection__group {
    .tox-collection__item {
      cursor: pointer;
      font-size: 14px !important;
      line-height: 24px !important;

      .tox-collection__item-label * {
        font-size: 14px !important;
        line-height: 24px !important;
        font-weight: 400 !important;
      }

      &:hover {
        background: var(--color-gray-735) !important;
      }

      &.tox-collection__item--active {
        background: var(--color-gray-735) !important;
      }
    }
  }
}

.modal-editor-action-field {
  padding-left: 8px;
  display: flex;
  align-items: center;
  min-width: 0;
  cursor: pointer;
  border-radius: 4px;

  &:hover {
    background: var(--color-gray-735) !important;
  }
}
