@import "~@ng-select/ng-select/themes/default.theme.css";

atl-drop-down-writing.ng-invalid.ng-touched {
  .ng-select-container {
    border: 1px solid var(--color-red-830)!important;
  }

}

.ng-select {
  cursor: pointer;

  &.ng-select-single .ng-select-container {
    font-family: 'SF Pro', serif;
    height: 40px;
    border: 1px solid var(--color-gray-525);
    color: var(--primary-text-color);
  }

  & .ng-select-container {
    border-radius: 8px;

    &:hover {
      border: 1px solid var(--color-black-745);
      box-shadow: unset;
      cursor: pointer;
    }
  }

  &.ng-select-focused > .ng-select-container, &.ng-select-opened > .ng-select-container {
    border: 1px solid var(--color-blue-980);
    box-shadow: 0 0 0 2px var(--color-gray-810)!important;
    color: var(--primary-text-color);
  }

  &.ng-select-single .ng-select-container .ng-value-container .ng-input {
    padding-top: 10px;
  }

  &.ng-select-opened.ng-select-bottom > .ng-select-container {
    border-radius: 8px;
    border: 1px solid var(--color-blue-980);
    box-shadow: 0 0 0 2px var(--color-gray-810);

    &:hover {
      border: 1px solid var(--color-black-745);
    }
  }

  &.ng-select-single .ng-select-container .ng-value-container .ng-input {
    padding-left: 16px;
    font-size: 14px;
  }

  &.ng-select.ng-select-disabled > .ng-select-container {
    background-color: unset;
  }

  &.ng-select-disabled .ng-arrow-wrapper {
    display: none;
  }

  & .ng-value-container .ng-placeholder {
    padding-left: 8px;
    color: var(--color-gray-902) !important;
    font-size: 16px;
    font-weight: normal;
    font-family: 'SF Pro', serif;
  }

  & .ng-dropdown-panel .ng-dropdown-panel-items {
    overflow-x: hidden;
    overflow-y: -moz-scrollbars-vertical;
    overflow-y: auto;

    &::-webkit-scrollbar {
      height: 30px;
      width: 5px;
    }

    &::-webkit-scrollbar-track {
      width: 2px;
    }

    &::-webkit-scrollbar-thumb {
      background-clip: padding-box;
      background: var(--color-black-750);
      border-bottom: 4px transparent solid;
      border-radius: 5px;
      border-top: 4px transparent solid;
    }

    scrollbar-color: var(--color-black-750) transparent;
    scrollbar-width: thin;
  }
}

.ng-select-disabled {
  &.ng-select-single .ng-select-container .ng-value-container .ng-value {
    color: var(--color-black-750);

  }

  &.ng-select-single .ng-select-container {
    border: 2px solid var(--color-gray-750);
  }
}

.global-label--special-style {
  &.ng-select .ng-arrow-wrapper .ng-arrow {
    display: none;
  }

  &.ng-select .ng-clear-wrapper {
    display: none;
  }

  &.ng-select .ng-select-container .ng-value-container .ng-placeholder {
    padding-left: 8px;
    color: var(--color-gray-800);
    font-size: 16px;
    font-weight: normal;
  }

  &.ng-select.ng-select-single .ng-select-container .ng-value-container,
  .ng-select.ng-select-single .ng-select-container .ng-value-container .ng-value {
    overflow: initial;
  }

  &.ng-select.ng-select-single .ng-select-container .ng-value-container .ng-input {
    width: 177px;
    padding-top: 4px;
  }

  & .ng-dropdown-panel.ng-select-bottom {
    width: 216px;
  }

  & .ng-dropdown-panel .ng-dropdown-panel-items .ng-optgroup, .ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
    white-space: normal;
    overflow: initial;
  }


  &.ng-select .ng-select-container {
    border: 2px solid transparent;
    transition: border 0.5s ease-in-out;

    &:hover {
      border: 2px solid rgb(var(--color-rgb-black-800));
    }
  }

  &.ng-select {
    &.ng-select-single .ng-select-container {
      width: 146px;
      height: 34px;
      font-family: 'SF Pro', serif;
    }
  }

  &.ng-dropdown-panel .ng-dropdown-panel-items .ng-optgroup,
  .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-select__not-found {
    font-family: 'SF Pro', serif;
    font-size: 14px;
    font-weight: 400;
    color: var(--color-red-900);
    line-height: 1.5;
  }
}

.ng-dropdown-panel {

  & .ng-dropdown-panel-items .ng-option.ng-select__not-found {
    font-family: 'SF Pro', serif;
    font-size: 14px;
    font-weight: normal;
    color: var(--color-black-750);
    line-height: 24px;
    display: flex;
    flex-direction: column;
  }

  &.ng-select-bottom {
    margin-top: 8px;
    border: 1px solid var(--color-gray-750);
    box-shadow: 0 16px 24px rgba(var(--color-rgb-black-900), 0.08);
    border-radius: 4px;
    scroll-snap-align: end;
  }

  &.ng-select-top {
    margin-bottom: 8px;
    border: 2px solid var(--color-gray-750);
    box-shadow: 0 16px 24px rgba(var(--color-rgb-black-900), 0.08);
    border-radius: 8px;
  }

  & .ng-dropdown-panel-items {
    padding: 8px;
  }

  &.ng-select-bottom .ng-dropdown-panel-items .ng-option:last-child {
    border-right: none;
  }

  .ng-option-disabled {
    cursor: not-allowed;
    pointer-events: none;
  }

}

.protocol-signatures {
  & > .ng-dropdown-panel {
    &.ng-select-bottom {
      width: 236px !important;
      min-width: 236px !important;
      margin-top: -6px;
      margin-left: 12px;
    }
  }
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-optgroup,
.ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
  padding: 8px 8px;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 400;
  color: var(--primary-text-color);

  &:not(:last-child) {
    margin-bottom: 8px;
  }
}

.ng-select.ng-select-single .ng-select-container .ng-value-container,
.ng-select.ng-select-single .ng-select-container .ng-value-container .ng-value {
  padding-top: 1px;
  padding-left: 8px;
  font-size: 16px;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected .ng-option-label,
.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected.ng-option-marked .ng-option-label {
  font-family: 'SF Pro', serif;
  font-weight: normal;
  font-size: 14px;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-marked {
  font-weight: normal;
  background-color: var(--color-white-1000);
  color: var(--primary-text-color) !important;

  &:hover {
    background: var(--color-gray-735);
  }
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected,
.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected.ng-option-marked {
  background: var(--color-gray-735);
}

.ng-select .ng-arrow-wrapper {
  width: 30px;
  height: 30px;
  padding: 0;
  margin-right: 10px;
}

.ng-select .ng-arrow-wrapper .ng-arrow {
  cursor: pointer;
  top: 3px;
  right: 3px;
  position: absolute !important;
  border: none;
  width: 24px !important;
  height: 24px !important;
  background-image: url(/assets/arrow_down.svg);
}

.ng-select.ng-select-opened > .ng-select-container .ng-arrow {
  top: 3px !important;
  transform: rotate(180deg);
}

.ng-select .ng-clear-wrapper {
  margin-right: 3px;
  color: var(--color-black-1000);
  opacity: 0.8;
}


.custom-input {
  &__checkbox {
    position: absolute;
    appearance: none;

    width: 20px;
    height: 20px;

    background-image: url(/assets/check_box_border.svg);

    cursor: pointer;

    &:checked {
      background-image: url(/assets/check_box_checked.svg);
    }

    &:active {
      background-image: url(/assets/check_box_active.svg);
    }

    &:active:checked {
      background-image: url(/assets/check_box_checked.svg);
    }

    &:disabled {
      cursor: not-allowed;
    }

    &-x16-blue {
      width: 16px;
      height: 16px;
      background-image: url(/assets/check_box_x16.svg);

      &:active {
        background-image: url(/assets/check_box_x16.svg);
      }

      &:checked {
        background-image: url(/assets/check_box_x16_active.svg);
      }

      &:active:checked {
        background-image: url(/assets/check_box_x16_active.svg);
      }
    }

    &-x16-blue-bd-2 {
      width: 16px;
      height: 16px;
      background-image: url(/assets/check_box_x16_bd_2.svg);

      &:active {
        background-image: url(/assets/check_box_x16_bd_2.svg);
      }

      &:checked {
        background-image: url(/assets/check_box_x16_active_bd_2.svg);
      }

      &:active:checked {
        background-image: url(/assets/check_box_x16_active_bd_2.svg);
      }

      &:disabled {
        background-image: url(/assets/check_box_x16_bd_2.svg);
      }

      &:checked:disabled {
        background-image: url(/assets/check_box_x16_active_disabled_bd_2.svg);
      }
    }

    &-x20-blue {
      width: 20px;
      height: 20px;
      background-image: url(/assets/check_box_x20.svg);

      &:active {
        background-image: url(/assets/check_box_x20.svg);
      }

      &:checked {
        background-image: url(/assets/check_box_x20_active.svg);
      }

      &:active:checked {
        background-image: url(/assets/check_box_x20_active.svg);
      }
    }

    &-x24-blue {
      width: 24px;
      height: 24px;
      background-image: url(/assets/check_box_x24.svg);

      &:active {
        background-image: url(/assets/check_box_x24.svg);
      }

      &:checked {
        background-image: url(/assets/check_box_x24_active.svg);
      }

      &:active:checked {
        background-image: url(/assets/check_box_x24_active.svg);
      }
    }
  }
}

.global-label {
  position: relative;
  display: flex;
  flex-direction: column;
  font-family: 'SF Pro', serif;
  font-size: 12px;

  & > span {
    font-size: 12px;
    letter-spacing: 0.15px;
    line-height: 16px;
    margin-bottom: 8px;
    min-height: 16px;
    text-transform: uppercase;
  }

  & > &__header-undo {
    display: flex;
    justify-content: space-between;
  }

  &__name {
    font-size: 12px;
    letter-spacing: 0.4px;
    line-height: 16px;
    margin-bottom: 8px;
    text-transform: uppercase;
  }

  .link {
    color: var(--color-blue-980);
    cursor: pointer;

    &:hover {
      color: var(--color-blue-911);
    }
  }

  &__icon {
    left: 8px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    user-select: none;
  }

  &--optional {
    font-size: 12px;
    color: var(--color-black-1000);
    opacity: 0.4;
  }
}

.global-caption {
  font-family: 'SF Pro', serif;
  margin-top: 8px;
  font-size: 12px;
  font-weight: 400;
  color: var(--color-black-750);

  &-auth {
    display: inline-block;
    line-height: 16px;
  }

  &--wrapper {
    height: 14px;
  }

  &--error {
    color: var(--color-red-900);
  }
}

.ngx-tel-input {
  &:-webkit-autofill {
    -webkit-box-shadow: inset 0 0 0 50px var(--color-white-1000), 0 0 0 2px var(--color-gray-810) !important;
  }
}

.global_input {
  height: 40px;
  padding: 8px 16px;
  font-family: "SF Pro", serif;
  font-size: 16px;
  font-weight: normal;
  box-sizing: border-box;
  border-radius: 4px;
  border: 1px solid var(--color-gray-525);
  line-height: 24px;

  &.client {
    border-radius: 8px;
  }

  &:-webkit-autofill {
    -webkit-box-shadow: inset 0 0 0 50px var(--color-white-1000)!important;
  }

  &::placeholder {
    font-family: 'SF Pro', serif;
    font-style: normal;
    font-weight: normal;
    line-height: 24px;

  }

  &:focus:-webkit-autofill {
    -webkit-box-shadow: inset 0 0 0 50px var(--color-white-1000), 0 0 0 2px var(--color-gray-810) !important;
  }

  &:focus::placeholder {
    color: transparent;
  }

  &--without-border {
    padding-left: 8px;
    width: 136px;
    height: 34px;
    border: 1px solid transparent;
  }

  &--clearable {
    padding-right: 28px;
  }

  &--date-time {
    width: 116px;

    &-disabled {
      background: var(--color-gray-650);
      color: var(--color-black-900);
      border: unset;
    }
  }

  &--textarea {
    padding-top: 12px;
    resize: none;
    height: 160px;
    font-family: "SF Pro", serif;

    &:disabled {
      background: var(--color-gray-735);
      color: var(--color-gray-890);
      border: unset;

      &:hover {
        border: none;
      }
    }
  }

  &:hover {
    border: 1px solid var(--color-black-745);
  }

  &:focus {
    border: 1px solid var(--color-blue-980);
    box-shadow: 0 0 0 2px var(--color-gray-810);
  }

  &.invalid {
    border: 1px solid var(--color-red-830)!important;
    box-shadow: none;
  }

  &::placeholder {
    color: var(--color-black-750);
    font-size: 16px;
    font-weight: normal;
    line-height: 24px;
  }
}


.custom-select-dropdown {
  font-family: 'SF Pro', serif;
  color: var(--color-black-1000);

  &__dropdown {
    padding: 11px 0 11px 16px;
    position: relative;
    width: 116px;
    border: 2px solid rgb(var(--color-rgb-black-700));
    border-radius: 8px;
    transition: 0.5s border ease-in-out;
    cursor: pointer;

    &--filter {
      width: 88px;
    }

    &--between {
      display: flex;
    }

    &--filter-indicator {
      margin-left: 6px;
      padding: 2px 12px;
      background-color: var(--color-gray-750);
      color: var(--color-white-1000);
      border-radius: 27px;
      cursor: pointer;
      transition: 0.5s background-color ease-in-out;

      &-fill {
        background-color: var(--color-blue-950);
        color: var(--color-white-1000);
      }

      &--button {
        padding: 4px 12px;
        border-radius: 8px;
        color: var(--color-black-1000);

        &:hover {
          background-color: var(--color-blue-950);
          color: var(--color-white-1000);
        }
      }
    }

    &--simple {
      width: 178px;
      padding: 8px 0 8px 16px;
    }

    &:hover {
      border: 1px solid var(--color-black-1000); //
    }

    &:focus {
      border: 1px solid var(--color-blue-950);
    }
  }

  &__selected {
    text-transform: capitalize;
    font-size: 16px;
  }

  &__arrow {
    position: absolute;
    top: 12px;
    right: 10px;

    &--simple {
      top: 8px;
    }
  }

  &__list {
    position: absolute;
    top: 58px;
    left: 11px;
    padding: 6px;
    width: 320px;
    border: 1px solid var(--color-gray-750);
    box-shadow: 0 16px 24px rgba(var(--color-rgb-black-900), 0.08);
    border-radius: 8px;
    font-size: 14px;
    z-index: 2;

    &--filter {
      width: 216px;
    }

    &--simple {
      width: 183px;
      top: 52px;
    }
  }

  &__group-title {
    padding: 12px 4px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 16px;
  }

  &__item-wrapper {
    padding-bottom: 8px;
    height: 110px;
    border-bottom: 1px solid var(--color-gray-750);
    overflow-y: scroll;
  }

  &__item {
    display: flex;
    align-items: center;
    padding: 6px 4px;
    cursor: pointer;
    text-transform: capitalize;
    transition: 0.5s background ease-in-out;

    &--no-padding {
      padding: 6px 0;
    }

    &:hover {
      background-color: rgb(var(--color-rgb-black-400));
      border-radius: 4px;
    }

    & > span {
      margin-left: 10px;
    }
  }

  &__filter-buttons {
    padding: 8px 0 4px 0;
    display: flex;
    justify-content: space-around;
  }
}

.ngx-tel-input {
  width: 100%;
  height: 40px;
  font-size: 16px;
  line-height: 24px;
  font-family: 'SF Pro', serif;
  background-clip: padding-box;
  border: 1px solid var(--color-gray-525);
  border-radius: 8px;
  background: none;

  &::placeholder {
    color: var(--color-black-750);
    font-size: 16px;
    line-height: 24px;
  }


  &:focus {
    border-color: var(--color-blue-980) !important;
    box-shadow: 0 0 0 2px var(--color-gray-810);
  }
  &:hover {
    border: 1px solid var(--color-black-745);
  }
}

.separate-dial-code {
  width: 100%;
  height: 40px;
  font-size: 16px;
  line-height: 24px;
  border-radius: 8px;
  background: none;
  margin-top: 8px;
}

.country-dropdown {
  position: inherit;
  margin-top: 8px !important;
  border-radius: 4px !important;
  border: 1px solid var(--color-gray-750) !important;
  overflow-x: hidden;
  font-size: 16px;
  box-shadow: 0 16px 24px rgba(var(--color-rgb-black-900), 0.08);
  background: var(--color-white-1000);
  z-index: 1;
}

.search-container {

  &:after {
    content: url(/assets/input_search_icon.svg);
    position: absolute !important;
    top: 15px !important;
    left: 15px !important;
  }
}

#country-search-box {
  font-family: 'SF Pro', serif;
  padding-left: 28px !important;
  font-size: 12px;
  line-height: 24px;
  width: 100%;
  border-radius: 8px;
  height: 32px;
  border: 0;
  box-sizing: border-box;
  background-color: var(--color-gray-735);
}

.iti {

  &__standard {
    width: calc(100% - 16px);
    margin-right: 5px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    box-sizing: border-box;
    cursor: pointer;
  }

  &__selected-flag .iti__flag {
    transform: scale(1.4);
  }

  &__flag {
    border-radius: 4px;
    width: 15px;
    height: 15px;
  }

  &.separate-dial-code input {
    padding-left: 128px !important;
  }

  &__flag-container.open {
    .selected-dial-code:after {
      display: inline-block;
      transform: rotate(180deg);
      margin-bottom: 10px;
    }
  }

  &__selected-flag {
    display: flex;
    justify-content: center;
    border-right: 2px solid var(--color-gray-750);
    border-top-left-radius: 6px 6px;
    border-bottom-left-radius: 6px 6px;
    background-clip: padding-box;
    padding: 6px;
    user-select: none;
    width: 116px !important;
  }

  &__country-list {
    overflow-x: hidden;
    height: 145px;
  }

  &__dial-code {
    float: right;
    color: var(--color-black-900);
  }

  .selected-dial-code {
    display: inline-flex;
    align-items: center;
    margin-left: 14px !important;

    &:after {
      content: url(/assets/arrow-down-sm.svg);
      position: relative !important;
      top: 3px !important;
      left: 6px !important;
    }
  }

  &__dial-code, &__country-name {
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
  }

  &__country {
    margin: 8px;

    &:hover {
      background: var(--color-gray-735) !important;
      border-radius: 4px;
    }
  }

  &__arrow {
    display: none;
  }

}

.table-checkbox {
  width: 16px;
  height: 16px;
  cursor: pointer;
}

input.global_input, input.global_input:focus, textarea.global_input:focus {
  &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    font-size: inherit;
    opacity: 1; /* Firefox */
    color: var(--color-gray-902);
  }

  *:-ms-input-placeholder { /* Internet Explorer 10-11 */
    opacity: 1;
    font-size: inherit;
    color: var(--color-gray-902);
  }

  &::-ms-input-placeholder { /* Microsoft Edge */
    opacity: 1;
    font-size: inherit;
    color: var(--color-gray-902);
  }
  &::-webkit-input-placeholder {
    opacity: 1;
    font-size: inherit;
    color: var(--color-gray-902);
  }
}

.newest-filter-item {
  position: relative;
  padding: 4px 8px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: var(--color-black-996);
  border-radius: 4px;
  cursor: pointer;

  &--active {
    background-color: var(--color-gray-440);
  }

  &:hover {
    background-color: var(--color-gray-440);
  }

  &-icon {
    position: absolute;
    top: 10px;
    right: 8px;
  }

  &-content {
    padding: 12px;
    position: absolute;
    right: 168px;
    top: 17px;

    background: var(--color-white-1000);
    border: 1px solid var(--color-gray-824);
    box-sizing: border-box;
    box-shadow: 0 4px 8px rgba(var(--color-rgb-black-900), 0.1);
    border-radius: 8px;

    z-index: 2;
  }
}

.global-newest-arrow {
  .ng-select .ng-arrow-wrapper .ng-arrow {
    top: 7px;
    right: 0;
    position: absolute !important;
    width: 16px !important;
    height: 16px !important;
    background-image: url(/assets/newest_arrow_down.svg);
  }

  .ng-select.ng-select-opened > .ng-select-container .ng-arrow {
    top: 7px !important;
    transform: rotate(180deg);
  }
}

.global-input-user {
  .global-label > span {
    margin-bottom: 12px;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    text-transform: unset;
    color: var(--color-gray-900);
  }

  .ng-select .ng-value-container .ng-placeholder {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: var(--color-gray-920);
  }

  .ng-dropdown-panel .ng-dropdown-panel-items .ng-optgroup,
  .ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
    padding: 4px 8px;
  }

  .ng-select.ng-select-single .ng-select-container .ng-value-container .ng-input {
    padding-top: 7px;
  }

  .ng-select.ng-select-single .ng-select-container .ng-value-container,
  .ng-select.ng-select-single .ng-select-container .ng-value-container .ng-value {
    padding-left: 3px;
    cursor: pointer;

    & > .global-input-user-user-section-wrapper .global-input-user-user-section-email {
      display: none;
    }
  }

  .ng-select .ng-select-container .ng-value-container .ng-input > input {
    cursor: pointer !important;
  }

  .ng-arrow-wrapper {
    display: none;
  }

  &:hover {
    .ng-select-container {
      border: 1px solid var(--color-gray-850);
    }

    .ng-arrow-wrapper {
      display: block;
    }
  }

  .ng-select.ng-select-focused > .ng-select-container {
    border: 1px solid var(--color-blue-1000) !important;

    & > .ng-arrow-wrapper {
      display: block;
    }
  }

  .ng-select .ng-value-container .ng-placeholder {
    padding-left: 12px;
  }

  &-user-section {
    &-wrapper {
      display: flex;
      align-items: center;
      cursor: pointer;

      & > span {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        color: var(--color-black-999);
      }
    }

    &-icon {
      width: 28px;
      height: 28px;
      display: flex;
      align-items: center;
      justify-content: center;

      margin-right: 8px;
      border-radius: 50%;
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 24px;
      color: var(--color-white-1000);
    }

    &-name {
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      color: var(--color-black-996);
    }

    &-email {
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      color: var(--color-gray-920);
    }
  }

  &--has-value {
    .ng-select.ng-select-single .ng-select-container .ng-value-container .ng-input {
      padding-left: 42px;
    }
  }
}

.global-input-newest-phone {
  .phone__header {
    display: none !important;
  }

  .ngx-tel-input {
    &:hover {
      border: 1px solid var(--color-gray-899);
    }

    &:focus {
      border: 1px solid var(--color-blue-930);
    }
  }

  .iti__flag-container.open.show {
    & + .ngx-tel-input {
      border: 1px solid var(--color-blue-930);
    }
  }

  .selected-dial-code {
    display: inline-flex;
    align-items: center;
    margin-left: 14px !important;

    &:after {
      content: url(/assets/newest-phone-arrow-down-sm.svg);
      position: relative !important;
      top: 2px !important;
      left: 6px !important;
    }
  }

  .iti {
    &__flag-container.open {
      .selected-dial-code:after {
        display: inline-block;
        transform: rotate(180deg);
        margin-bottom: 4px;
      }
    }
  }

  .phone__label {
    text-transform: unset;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    color: var(--color-gray-900);
  }

  .separate-dial-code {
    height: 32px;
  }

  .iti__flag-container.open.show {
    & > div:first-child {
      background-color: var(--color-gray-822);
    }
  }

  .iti__flag-container {
    padding: 4px;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: var(--color-black-999);
  }

  .iti.separate-dial-code input {
    padding-left: 102px !important;
  }

  .iti.separate-dial-code .iti__selected-flag,
  .iti.separate-dial-code.iti--allow-dropdown.iti-sdc-2 .iti__selected-flag,
  .iti.separate-dial-code.iti--allow-dropdown.iti-sdc-3 .iti__selected-flag,
  .iti.separate-dial-code.iti--allow-dropdown.iti-sdc-4 .iti__selected-flag {
    width: 93px !important;
    border: none;
    border-radius: 6px;

    &:hover {
      background-color: var(--color-gray-822);

      & + .ngx-tel-input {
        border: 1px solid var(--color-gray-899);
      }
    }
  }

  .ngx-tel-input {
    padding-left: 100px !important;
    height: 32px !important;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: var(--color-black-999);
    border: 1px solid transparent;
    border-radius: 8px;
  }

  &.invalid {
    .ngx-tel-input {
      border: 1px solid var(--color-red-900);
    }
  }
}

.newest-input {
  &--phone {
    &.ng-touched.ng-invalid .ngx-tel-input {
      border: 1px solid var(--color-red-900);
    }

    .separate-dial-code {
      width: 100%;
      height: 32px;
      font-size: 14px;
      line-height: 24px;
      border-radius: unset;
      background: none;
      margin-top: 0;

    }

    .iti.separate-dial-code input {
      padding-left: 78px !important;
    }

    .ngx-tel-input {
      width: 100%;
      height: 32px;
      font-size: 14px;
      line-height: 24px;
      font-family: "SF Pro", serif;
      background-clip: padding-box;
      border: none;
      border-radius: 4px;
      background: none;

      &::placeholder {
        font-size: 14px;
        line-height: 24px;
        color: var(--color-gray-902);
      }

      &:hover {
        background: var(--color-gray-735);
      }

      &:focus {
        border: 1px solid var(--color-gray-525) !important;
        box-shadow: 0 6px 8px -4px rgba(var(--color-rgb-black-840), 0.2);

        &:hover {
          background: var(--color-white-1000);
        }
      }
    }

    .iti__flag-container {
      padding: 3px;

      &:hover .iti__selected-flag {
        background: var(--color-gray-735);
      }

      .iti__selected-flag {
        border-right: none;
        height: 24px;
        top: 3px;
        padding: 0 4px;
        left: 3px;
        border-radius: 4px;
        width: 70px !important;

        .iti__flag {
          height: 15px;
          width: 15px;
          flex-shrink: 0;
          transform: scale(1.05);
          border-radius: 2px;
        }

        .selected-dial-code {
          margin-left: 4px !important;

          &:after {
            content: url(/assets/arrow-newest-gray-down.svg);
            left: 2px !important;
            top: 2px !important;
          }
        }
      }
    }

    .iti__flag-container.open .selected-dial-code:after {
      margin-bottom: 5px;
    }

    &-border {
      .separate-dial-code {
        width: 100%;
        height: 40px;
        font-size: 14px;
        line-height: 24px;
        background: none;
        margin-top: 0;
        border: 1px solid var(--color-gray-525);
        border-radius: 4px !important;
      }

      .ngx-tel-input {
        height: 40px;

        &:hover {
          background: var(--color-gray-735);
        }

        &:focus {
          border: unset;
          &:hover {
            background: var(--color-white-1000);
          }
        }
      }

      .iti__flag-container {
        .iti__selected-flag {
          height: 32px;
        }
      }
    }
  }

  &--biggest {
    .global_input {
      width: 266px;
      padding: 5px 0 5px 8px;
      height: 40px;
      font-style: normal;
      font-weight: 600;
      font-size: 24px;
      line-height: 32px;
      color: var(--color-black-999);

      border: 1px solid transparent;
      border-radius: 8px;

      &:hover {
        border: 1px solid var(--color-gray-850);
      }

      &:focus {
        border: 1px solid var(--color-blue-1000);
      }
    }

    &.ng-touched.ng-invalid {
      .global_input {
        border: 1px solid var(--color-red-900);
      }
    }

    .input__hidden-checker {
      font-style: normal;
      font-weight: 600;
      font-size: 24px;
      line-height: 32px;
    }
  }

  &--store {
    .global_input {
      width: 266px;
      padding: 5px 0 5px 8px;
      height: 32px;
      font-weight: 600;
      font-size: 24px;
      line-height: 32px;
      color: var(--color-black-996);
      border: none;
      border-radius: 0;

      &:hover {
        border: none;
      }

      &:focus {
        border: none;
      }

      &:disabled {
        font-weight: 600;
        font-size: 24px;
        line-height: 32px;
        color: var(--color-gray-910);
      }
    }

    &.ng-touched.ng-invalid {
      .global_input {
        border: 1px solid var(--color-red-900) !important;
        border-radius: 4px;
      }
    }

    .input__hidden-checker {
      font-style: normal;
      font-weight: 600;
      font-size: 24px;
      line-height: 32px;
    }
  }

  &--number {
    .global_input {
      width: 160px;
      height: 32px;
      padding: 5px 0 5px 8px;
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
      color: var(--primary-text-color);
      border: none;
      border-radius: 4px !important;

      &:hover {
        border: none;
        background: var(--color-gray-735) !important;
      }

      &:focus {
        border: 1px solid var(--color-gray-525);
        box-shadow: 0 6px 8px -4px rgba(var(--color-rgb-black-840), 0.2);

        &:hover {
          background: var(--color-white-1000) !important;
        }
      }

      &:disabled {
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        color: var(--color-black-996);
        -webkit-text-fill-color: var(--color-gray-910);

        &:hover {
          background: var(--color-gray-650) !important;
        }
      }
    }

    &.ng-touched.ng-invalid {
      .global_input {
        border: 1px solid var(--color-red-900) !important;
        border-radius: 4px;
      }
    }

    .input__hidden-checker {
      font-style: normal;
      font-weight: 600;
      font-size: 24px;
      line-height: 32px;
    }
  }
}

@mixin descending-z-index($count: 30) {
  position: relative;
  $target: 0;
  $index: $count;
  @while $index > 0 {
    &:nth-child(#{$target}) {
      z-index: #{$index};
    }
    $target: $target + 1;
    $index: $index - 1;
  }
}


.multiple-select {
  &-component {
    .ng-select {
      & .ng-value-container .ng-placeholder {
        font-size: 14px;
        line-height: 24px;
        color: var(--color-gray-902) !important;
      }

      & .ng-select-container {
        min-height: 32px;
        height: 32px;
        border: 1px solid transparent;
        border-radius: 4px;
      }

      & .ng-select-container .ng-value-container .ng-input > input:not(:disabled) {
        cursor: pointer !important;
      }

      &.ng-select-single .ng-select-container {
        height: 40px;
        border: 1px solid transparent;

        &:hover {
          background: var(--color-gray-735);
          box-shadow: 0 6px 8px -4px rgba(var(--color-rgb-black-840), 0.2);
          border-radius: 4px;
        }
      }

      &.ng-select-single .ng-select-container .ng-value-container .ng-input {
        padding-top: 7px;
      }

      &.ng-select-focused > .ng-select-container, &.ng-select-opened > .ng-select-container {
        border: 1px solid var(--color-gray-805) !important;
        background: var(--color-white-1000);
        border-radius: 4px;
        box-shadow: unset !important;
      }

      &.ng-select-opened.ng-select-bottom > .ng-select-container {
        border-radius: 4px 4px 0 0;
      }

      &.ng-select-opened.ng-select-top > .ng-select-container {
        border-radius: 0 0 4px 4px;
      }

      &.ng-select-multiple .ng-select-container .ng-value-container {
        padding-top: 2px;
      }

      &.ng-select-multiple .ng-select-container .ng-value-container .ng-value {
        background: transparent;
        padding: 0 8px 0 4px;
        border-radius: 4px;
      }

      &.ng-select-multiple .ng-select-container .ng-value-container .ng-placeholder {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        left: 7px;
        right: 0;
        top: 4px;
      }

      &.ng-select-multiple .ng-select-container .ng-value-container .ng-value {
        padding: 0 4px 0 4px;
        margin-bottom: 2px;
      }

      &.ng-select-opened.ng-select-multiple .ng-select-container .ng-value-container .ng-value {
        background: var(--color-black-720) !important;
        border-radius: 4px;

        & > .multiple-select-user-card-wrapper > div > .multiple-select-user-card-remove {
          display: block;
        }
      }
    }

    .ng-dropdown-panel .ng-dropdown-panel-items .ng-optgroup,
    .ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
      padding: 4px 8px;

      &:not(:last-child) {
        margin-bottom: 8px;
      }

      .multiple-select-user-card-selection-checkbox {
        display: inline-block;
      }
    }



    .ng-select.ng-select-single .ng-select-container .ng-value-container,
    .ng-select.ng-select-single .ng-select-container .ng-value-container .ng-value {
      padding-left: 3px;
    }

    .ng-arrow-wrapper {
      display: none;
    }

    &:hover {
      .ng-select-container {
        background: var(--color-gray-735);
      }
    }

    .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected,
    .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected.ng-option-marked {

      .remove-dropdown-item {
        display: none;
      }

      &:hover {
        background: var(--color-gray-735);
      }

      & > .multiple-select-user-card-wrapper > .multiple-select-user-card-checkbox {
        display: block;
      }

      & > .multiple-select-user-card-wrapper > div {
        & > span, & > p {
          color: var(--color-blue-980) !important;
        }
      }
    }

    .ng-dropdown-panel {
      &.ng-select-bottom {
        margin-top: 0 !important;
        border: 1px solid var(--color-gray-805);
        border-top: none;
        box-shadow: 0 16px 24px rgba(var(--color-gray-730), 0.08);
        border-radius: 0 0 4px 4px;
        scroll-snap-align: end;
        background: var(--color-white-1000);
      }
    }
  }

  &-user-card {
    &-wrapper {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: space-between;

      & > div {
        display: flex;
        min-width: 0;

        &:first-child {
          flex-shrink: 0;
        }
      }

      & > div:not(.align-items-start) {
        align-items: center;
      }

      & > div > span {
        font-family: 'SF Pro', serif;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        color: var(--primary-text-color);

        &.text-gray {
          color: var(--color-gray-890);
        }
      }
    }

    &-icon {
      display: flex;
      width: 24px;
      height: 24px;
      align-items: center;
      justify-content: center;

      margin-right: 8px;
      border-radius: 50%;
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 24px;
      // [5313] Hide monochrome icons temporarily.
      // color: var(--color-black-960);
      color: var(--color-white-1000);
    }

    &-user-count {
      margin-left: 7px;
      border-radius: 4px;
      padding: 0 4px 0 4px;
      display: flex;
      align-items: center;
      background: var(--color-black-720);

      & > span {
        font-family: 'SF Pro', serif;
        font-size: 14px;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0;
        color: var(--color-gray-890);
      }
    }

    &-checkbox {
      display: none;
    }

    &-selection-checkbox {
      display: none;
    }

    &-remove {
      padding-left: 8px;
      display: none;
    }
  }

  &-user-chips {
    &.no-collapse {
      .ng-select {
        &.ng-select-multiple .ng-select-container .ng-value-container .ng-value {
          padding: 0 4px;
          background: var(--color-black-720) !important;
          border-radius: 4px;

          &:not(:nth-child(2)) {
            margin-left: 0;
          }

          & > .multiple-select-user-card-wrapper > div {
            .user {
              width: 24px;
              height: 24px;
            }
            .multiple-select-user-card-icon {
              margin-right: 4px !important;
            }
            .multiple-select-user-card-remove {
              display: block !important;
            }

            & > span {
              display: block;
            }
          }
        }
      }
    }



    .ng-select {
      & .ng-select-container {
        height: auto;
      }

      &.ng-select-multiple .ng-select-container .ng-value-container .ng-value {
        padding: 0;

        &:not(:nth-child(2)) {
          margin-left: -24px;
        }

        &.multi-limit {
          margin-left: 0 !important;
          font-weight: 400;
          font-size: 14px;
          line-height: 24px;
          color: var(--primary-text-color);
        }

        @include descending-z-index(11);

        & > .multiple-select-user-card-wrapper > div > span {
          display: none;
        }
      }

      &.ng-select-focused.ng-select-multiple .ng-select-container .ng-value-container .ng-value {
        padding: 0 8px 0 4px;

        &:not(:nth-child(2)) {
          margin-left: 0;
        }

        & > .multiple-select-user-card-wrapper > div > span {
          display: block;
        }
      }
    }
  }

  &-not-found-section {
    &__wr {
      display: flex;
      align-items: center;
      padding: 4px 8px;
      width: 100%;
      background-color: var(--color-gray-735);
      border-radius: 4px;
    }

    &__text {
      font-family: 'SF Pro', serif;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
      color: var(--primary-text-color);
    }

    &__button {
      margin-left: 8px;
      padding: 2px 8px;
      background: var(--color-gray-455);
      border-radius: 4px;
      font-family: 'SF Pro', serif;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
      color: var(--primary-text-color);
    }
  }
}
