.spinner {
  margin: -3px 0;
  width: 20px;
  height: 20px;
  box-sizing: border-box;
  background-position: center;
  background-repeat: no-repeat;
  animation: spin .6s linear infinite;
}

@keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
  }
}

.custom-spinner {
  display: inline-block;
  border: 2px solid var(--color-black-750);
  border-radius: 50%;
  border-top-color: transparent;
  animation: spin 1s ease-in-out infinite;
  -webkit-animation: spin 1s ease-in-out infinite;
  flex-shrink: 0;

  &.custom-spinner-black {
    border-color: var(--color-black-960);
    border-top-color: transparent;
  }

  &-12 {
    width: 12px;
    height: 12px;
  }

  &-16 {
    width: 16px;
    height: 16px;
  }

  &-17 {
    width: 17px;
    height: 17px;
  }

  &-20 {
    width: 20px;
    height: 20px;
  }

  &-24 {
    width: 24px;
    height: 24px;
  }

  &-30 {
    width: 30px;
    height: 30px;
  }

  &-32 {
    width: 32px;
    height: 32px;
  }

  &-48 {
    width: 48px;
    height: 48px;
  }
}



@keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
  }
}

.spinner-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  &__messages {
    height: calc(100vh - 80px);
  }

  &--main-preloader {
    height: 100vh;
  }

  &__packages {
    height: calc(100vh - 126px);
  }
}

.project-wrapper {
  height: var(--admin-page-without-header);
}

.ng-select .ng-spinner-loader {
  border: 2px solid var(--color-blue-980) !important;
  border-left-color: var(--color-gray-525) !important;
}
