 $tabletSize: 1024px;
$mobileSize: 744px;
$modalTopPosition: 72px;
$modalOffset: 16px;
$space: 48px;
$tabletModalSize: 800px;
$macFullWidth: 1440px;
$modalWidth: 768px;

@mixin modal-styles {
  width: 400px;
  border-radius: 8px;
  box-shadow: 0 16px 24px rgba(var(--color-rgb-black-900), 0.08);
  border: none;
  font-size: 14px;
}

.custom-modal {

  &-main {

    &.fade .custom-modal--right {
      transform: translate(100%, 0);
    }

    &.show .custom-modal--right {
      transform: translate(0, 0);
    }

    &--without-backdrop {
      &-contact {
        top: $modalTopPosition;
        left: calc(100vw - 436px);
      }

      &-small {
        top: $modalTopPosition;
        left: calc(100vw - 497px);
      }

      &-settings {
        left: calc(100vw - 497px);
      }

      &-large {
        top: $modalTopPosition;
        left: calc(100vw - 641px);
      }
    }
  }

  &--product-library-preview {
    width: 100vw;

    .modal-content {
      max-width: $mobileSize;
      height: calc(var(--viewport-height) - 48px);
      border-radius: 8px;
      box-shadow: 0 4px 24px -4px rgba(var(--color-rgb-black-840), 0.2);
    }
  }

  &--shadow {
    filter: drop-shadow(0 16px 24px rgba(var(--color-rgb-black-900), 0.08));
  }

  &--left {
    margin: 0;
    position: fixed;
    top: var(--modal-top-position);
    left: 0;
    bottom: 0;

    &__modal-text-editor {
      top: 0;
      width: 100%;
      max-width: 624px;
      padding: 16px 0 16px 16px;

      @media (min-width: $macFullWidth) {
        left: unset;
        right: calc($modalWidth + 32px);
      }

      .modal-content {
        height: 100%;
        box-shadow: 0 4px 24px -4px rgba(var(--color-rgb-black-840), 0.2);
        border-radius: 8px;
        border: none;
      }
    }
  }

  &--right {
    margin: 0;
    width: 436px;
    position: fixed;
    top: var(--modal-top-position);
    right: 0;
    bottom: 0;

    &-task-modal {
      width: 496px;
    }

    &--right-admin {
      top: 0;
    }

    @media (max-width: $mobileSize) {
      top: 57px;
    }

    @media (max-width: 576px) {
      width: 100vw;
    }

    &-mobile {
      @media (max-width: $mobileSize) {
        top: 64px;
      }
    }


    & .modal-content {
      width: auto;
      height: calc(100vh - 64px);
      border: none;
      border-radius: 0;

      @media (max-width: $mobileSize) {
        height: calc(100vh - 57px);
      }
    }

    &-client {
      @media (max-width: $mobileSize) {
        width: 100%;
      }
      top: var(--modal-top-position);
      width: 640px;
      filter: drop-shadow(0 16px 24px rgba(var(--color-rgb-black-900), 0.08));

      .modal-content {
        height: 100vh;
      }
    }

    &-admin {
      top: 0;
      width: 496px;
      box-shadow: -8px 0px 24px rgba(var(--color-rgb-black-900), 0.08);

      &-medium {
        top: 0;
        width: 720px;
        max-width: 720px;
        box-shadow: -8px 0px 24px rgba(var(--color-rgb-black-900), 0.08);

        .modal-content {
          height: 100vh;
        }
      }

      &-admin-task {
        top: 0;
        width: 900px;
        max-width: 900px;
        box-shadow: -8px 0px 24px rgba(var(--color-rgb-black-900), 0.08);

        .modal-content {
          height: 100vh;
        }
      }

      &-large {
        top: 0;
        margin: 16px 16px 16px 0;
        width: 100%;
        max-width: 924px;
        border-radius: 8px;
        box-shadow: -8px 0px 24px rgba(var(--color-rgb-black-900), 0.08);

        .modal-content {
          height: 100%;
          border-radius: 8px;
        }
      }

      &-unit-modal {
        top: 0;
        width: 800px;
        max-width: 800px;
        box-shadow: -8px 0px 24px rgba(var(--color-rgb-black-900), 0.08);

        .modal-content {
          height: 100vh;
        }
      }

      &-option-store-modal {
        top: 0;
        width: 768px;
        max-width: 768px;
        padding: 16px 16px 16px 0;

        &.client, &.client-radius {
          .modal-content {
            border-radius: 24px;

            @media (max-width: $mobileSize) {
              border-radius: unset;
            }
          }
        }

        @media (max-width: $tabletModalSize) {
          width: calc(100% - 32px);
          max-width: 100%;
          padding: 16px;
        }

        @media (max-width: $mobileSize) {
          padding: 0;
          width: 100%;
          height: 100%;
        }


        .modal-content {
          height: 100%;
          box-shadow: 0 4px 24px -4px rgba(var(--color-rgb-black-840), 0.2);
          border-radius: 8px;
        }

        &-creation {
          margin: auto;
          position: initial;
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 0;

          &.client {
            .modal-content {
              border-radius: 16px;
            }
          }

          .modal-content {
            width: $mobileSize;
            height: calc(var(--viewport-height) - 172px);
          }
        }

        &-package {
          margin: auto;
          position: initial;
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 0;

          .modal-content {
            width: $mobileSize;
            height: calc(var(--viewport-height) - 96px);
          }
        }

        &-old-width {
          width: 496px;
        }
      }

      &-meeting-book-time {
        max-width: 920px;
        width: 100%;
        margin: auto;
        position: initial;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0;

        @media (max-width: $tabletSize) {
          padding: 16px;
          box-sizing: border-box;
          height: 100%;
        }

        .modal-content {
          max-width: 920px;
          width: 100%;
          height: 560px;
          border-radius: 24px;

          @media (max-width: $tabletSize) {
            max-height: 100%;
            height: fit-content;
          }
        }
      }

      &-client-right-side {
        top: 0;
        width: 100%;
        max-width: $modalWidth;
        padding: 16px;

        &.client-radius .modal-content {
          border-radius: 24px;

          @media (max-width: $mobileSize) {
            border-radius: unset;
          }
        }

        @media (max-width: calc($mobileSize + 16px)) {
          width: 100%;
          max-width: 100%;
          padding: 0;
        }

        .modal-content {
          height: 100%;
          box-shadow: 0 4px 24px -4px rgba(var(--color-rgb-black-840), 0.2);
          border-radius: 24px;

          @media (max-width: calc($mobileSize + 16px)) {
            width: 100%;
            border-radius: 0;
          }
        }

      }

      & .modal-content {
        height: 100vh;
      }
    }

    &-product {
      max-width: 944px;
      top: 0;
      width: 100vw;

      .modal-content {
        height: 100vh;
      }
    }

    &-product-library {
      max-width: $mobileSize;
      top: $modalOffset;
      right: $modalOffset;
      width: 100vw;

      @media (max-width: 800px) {
        width: calc(100vw - 2 * 16px);
      }

      .modal-content {
        height: calc(100vh - 32px);
        border-radius: 8px;
        box-shadow: 0 4px 24px -4px rgba(var(--color-rgb-black-840), 0.2);
      }
    }

    &-reclamation {
      max-width: $mobileSize;
      top: $modalOffset;
      right: $modalOffset;
      width: calc(100vw - 2 * #{$modalOffset}) !important;

      @media (max-width: $mobileSize) {
        top: 0;
        right: 0;
        width: 100vw !important;
      }

      &.unit-list {
        .modal-content {
          height: calc(var(--viewport-height) - 2 * #{$modalOffset});
        }
      }

      &.client-radius {
        .modal-content {
          border-radius: 24px;

          @media (max-width: $mobileSize) {
            border-radius: unset;
          }
        }
      }

      .modal-content {
        height: calc(var(--viewport-height) - 2 * #{$modalOffset});
        border-radius: 8px;
        box-shadow: 0 4px 24px -4px rgba(var(--color-rgb-black-840), 0.2);

        @media (max-width: $mobileSize) {
          height: var(--viewport-height);
        }
      }
    }

    &-connected-units {
      max-width: $mobileSize;
      top: $modalOffset;
      right: $modalOffset;
      width: calc(100vw - 2 * #{$modalOffset}) !important;

      .modal-content {
        height: calc(100svh - 2 * #{$modalOffset});
        border-radius: 8px;
        box-shadow: 0 4px 24px -4px rgba(var(--color-rgb-black-840), 0.2);
      }
    }

    &-protocol-reclamation {
      top: 158px;
      right: 80px;
      width: 480px !important;

      .modal-content {
        height: 488px;
        max-height: calc(100vh - 300px);
        border-radius: 8px;
        box-shadow: 0 4px 24px -4px rgba(var(--color-rgb-black-840), 0.2);
      }
    }

    &-protocol-detail-reclamation {
      top: 158px;
      right: 80px;
      width: 480px !important;

      .modal-content {
        height: 598px;
        max-height: calc(100vh - 260px);
        border-radius: 8px;
        box-shadow: 0 4px 24px -4px rgba(var(--color-rgb-black-840), 0.2);
      }
    }

    &-invite {
      top: 56px;
      width: 496px;

      & .modal-content {
        box-shadow: -8px 0px 24px rgba(var(--color-rgb-black-900), 0.08);
      }
    }

    &-member {
      top: 57px;
      width: 496px;

      & .modal-content {
        box-shadow: -8px 0px 24px rgba(var(--color-rgb-black-900), 0.08);
        height: 100%;
      }
    }

    &-chat-documents, &-chat-project-documents, &-unit-chat-documents, &-reclamation-chat-documents {
      width: 496px;
      box-shadow: -8px 9px 24px 0px var(--color-gray-675);
    }

    &-chat-documents {
      top: 132px;
      padding: 16px 16px 16px 0;
    }

    &-unit-chat-documents {
      top: 0;
      padding: 16px 16px 16px 0;
      box-shadow: none;

      & .modal-content {
        height: 100%;
        box-shadow: 0 4px 24px -4px rgba(var(--color-rgb-black-840), 0.2);
        border-radius: 8px;
      }
    }

    &-reclamation-chat-documents {
      top: unset;
      right: 16px;
      bottom: 16px;
      border-radius: 10px;
      overflow: hidden;
    }

    &-chat-project-documents {
      top: 157px;
    }

    &-chat-multi-select {
      top: 161px;
    }

    &-thread-attachments {
      max-width: 768px;
      top: 16px;
      right: 16px;
      width: 100vw;

      &.client-radius {
        .modal-content {
          border-radius: 24px;
        }
      }

      .modal-content {
        height: calc(100vh - 32px);
        border-radius: 8px;
        box-shadow: 0 4px 24px -4px rgba(var(--color-rgb-black-840), 0.2);
      }

      @media (max-width: $tabletSize) {
        max-width: 736px;
      }

      @media (max-width: $mobileSize) {
        top: 0;
        right: 0;
        left: 0;

        .modal-content {
          height: calc(100svh);
          border-radius: 0px!important;
          box-shadow: none;
        }
      }
    }

  }

  &--center {
    &-reclamation {
      max-width: $mobileSize;
      top: $modalOffset;
      right: $modalOffset;
      width: calc(100vw - 2 * #{$modalOffset}) !important;

      @media (max-width: $mobileSize) {
        top: 0;
        right: 0;
        width: 100vw !important;
      }

      &.unit-list {
        .modal-content {
          height: calc(var(--viewport-height) - 2 * #{$modalOffset});
        }
      }

      &.client-radius {
        .modal-content {
          border-radius: 24px;

          @media (max-width: $mobileSize) {
            border-radius: unset;
          }
        }
      }

      .modal-content {
        height: calc(var(--viewport-height) - 2 * #{$modalOffset});
        border-radius: 8px;
        box-shadow: 0 4px 24px -4px rgba(var(--color-rgb-black-840), 0.2);
        overflow: hidden;

        @media (max-width: $mobileSize) {
          height: var(--viewport-height);
        }
      }
    }
  }

  &-overlay {
    &--hidden {
      display: none;
    }
  }
}

.modal-reset-password {
  & .modal-content {
    width: 496px;
    min-height: 250px;

    @media (max-width: $mobileSize) {
      & {
        margin: 0 32px;
      }
    }
  }
}

.modal-import-product {
  & .modal-content {
    border-radius: 8px;
    width: 496px;
    min-height: 250px;
    margin-top: 48px;
    @media (max-width: $mobileSize) {
      & {
        margin: 0 32px;
      }
    }
  }
}

.modal-confirm-design-line {
  & .modal-content {
    border-radius: 8px;
    width: 496px;
    min-height: 225px;

    @media (max-width: $mobileSize) {
      & {
        margin: 0 32px;
      }
    }
  }
}

.modal-price-summary {
  & .modal-content {
    width: 496px;
    height: 392px;
    border-radius: 24px;

    @media (max-width: $mobileSize) {
      height: var(--viewport-height);
      border-radius: 0;
      width: 100%;
    }
  }
}

.modal-center-768 {
  height: 100%;

  .modal-content {
    width: 100%;
    max-width: 768px;
    height: calc(100% - 94px);
    border-radius: 8px;
  }

  &.client-radius {
    .modal-content {
      border-radius: 24px;
    }
  }
}

.modal-notification {
  .modal-dialog {
    margin: unset;
  }
  .modal-content {
    border-radius: 8px;
    width: min(464px, 100vw);
  }
}

.modal-notification-new {
  .modal-content {
    width: 496px;
  }
}

.white-overlay {
  background: var(--color-gray-650);
  opacity: 0.72;
  margin-top: 55px;
}

// Todo: ask the design team to provide corresponding colors instead of opacity
.default-overlay {
  max-width: calc(100vw - 924px);
  background: var(--color-black-1000);
  opacity: 0.48;
}

.default-overlay-1190 {
  max-width: calc(100vw - 1190px);
  background: var(--color-black-1000);
  opacity: 0.48;
}

.default-overlay-md {
  max-width: calc(100vw - 720px);
  background: var(--color-black-1000);
  opacity: 0.48;
}


.transparent-overlay {
  opacity: 0 !important;
}

.modal-admin--left {

  display: flex !important;
  align-items: center !important;

  &.client-radius {
    .modal-content {
      border-radius: 24px;
    }
  }

  & > .modal-dialog {
    flex-grow: 1;
    display: flex;
    justify-content: flex-end;
    margin-right: 16px;

    &.fade {
      transform: translate(100%, 0);
    }

    &.show {
      transform: translate(0, 0);
    }

    & > .modal-content {
      width: $mobileSize;
      height: calc(100svh - 2 * #{$modalOffset});
      border: 1px solid var(--color-gray-750);
      box-shadow: 0 4px 24px -4px rgba(var(--color-rgb-black-840), 0.2);
    }
  }
}

.centered-modal-admin-1080 {
  display: flex !important;
  align-items: center !important;

  & > .modal-dialog {
    @media (max-width: 1300px) {
      transform: translate(0, 0) !important;
    }

    & > .modal-content {
      width: 1080px;
      height: 640px;
      border: 1px solid var(--color-gray-750);
      box-shadow: -8px 9px 24px rgba(var(--color-rgb-black-900), 0.08);
      position: absolute;
      right: 266px;
      border-radius: 12px;
    }
  }
}

.centered-modal-crop-image {
  display: flex !important;
  align-items: center !important;

  & > .modal-dialog {
    & > .modal-content {
      border: 1px solid var(--color-gray-750);
      box-shadow: -8px 9px 24px rgba(var(--color-rgb-black-900), 0.08);
      border-radius: 8px;
    }
  }
}

.centered-modal-1152 {
  display: flex !important;
  align-items: center !important;

  & > .modal-dialog {
    & > .modal-content {
      width: 1152px;
      height: 640px;
      border: 1px solid var(--color-gray-750);
      box-shadow: -8px 9px 24px rgba(var(--color-rgb-black-900), 0.08);
      border-radius: 24px;
      overflow: hidden;
      @media (max-width: $mobileSize) {
        width: 100vw;
        height: var(--viewport-height);
        border-radius: 0;
      }
    }
  }
}

.centered-modal-944 {
  display: flex !important;
  align-items: center !important;

  & > .modal-dialog {
    & > .modal-content {
      width: 944px;
      height: calc(100vh - 96px);
      border: 1px solid var(--color-gray-750);
      box-shadow: -8px 9px 24px rgba(var(--color-rgb-black-900), 0.08);

      @media (max-width: $mobileSize) {
        width: 100vw;
        height: 100svh;
        border-radius: 0;
        max-width: none;
      }
    }
  }
}

.centered-modal-1344 {
  display: flex !important;
  align-items: center !important;
  & > .modal-dialog {
    & > .modal-content {
      width: calc(100vw - 96px);
      height: calc(100vh - 96px);
      border: 1px solid var(--color-gray-750);
      box-shadow: -8px 9px 24px rgba(var(--color-rgb-black-900), 0.08);
      border-radius: 24px;

      @media(max-width: $mobileSize) {
        width: 100vw;
        height: 100svh;
        border-radius: 0;
      }
    }
  }
}

.centered-modal-496 {
  display: flex !important;
  align-items: center !important;
  & > .modal-dialog {
    & > .modal-content {
      width: 496px;
      height: calc(100dvh - 96px);
      border: 1px solid var(--color-gray-750);
      box-shadow: -8px 9px 24px rgba(var(--color-rgb-black-900), 0.08);
      border-radius: 24px;

      @media(max-width: $mobileSize) {
        width: 100vw;
        height: 100svh;
        border-radius: 0;
      }
    }
  }
}

.centered-modal-admin-825 {
  display: flex !important;
  align-items: flex-end !important;

  & > .modal-dialog {
    & > .modal-content {
      width: 825px;
      height: 428px;
      border: 1px solid var(--color-gray-750);
      box-shadow: -8px 9px 24px rgba(var(--color-rgb-black-900), 0.08);
    }
  }
}

.centered-modal-admin-768 {
  height: 100vh;
  padding: 48px 24px;

  @media(max-width: $mobileSize) {
    padding: 0;
    margin: 0;
  }

  .modal-content {
    width: 100%;
    max-width: 768px;
    border-radius: 8px;
    box-shadow: 0 16px 24px rgba(var(--color-rgb-black-900), 0.08);
    border: none;
    font-size: 14px;
    height: auto;
    max-height: 100%;

    @media(max-width: $mobileSize) {
      width: 100vw;
      height: var(--viewport-height);
      border-radius: 0;
    }
  }
}

.centered-transfer-modal {
  .modal-dialog-centered {
    height: 100vh;
  }

  .modal-content {
    width: 496px;
    border-radius: 8px;
    box-shadow: 0 4px 24px -2px rgba(var(--color-rgb-black-830), 0.16), inset 1px 0 0 var(--color-gray-820);
    border: none;
    font-size: 14px;
  }
}

.confirm-action-modal-v2 {
  &.rule-delete-modal, &.qr-code-download {
    .modal-content {
      width: 496px;
    }
  }
  &.confirm-transfer-modal {
    .modal-content {
      width: 464px;
    }
  }
  &.preview-popup {
    .modal-content {
      width: 480px;
      border-radius: 24px;
    }
  }
  .modal-content {
    width: 550px;
    border-radius: 8px;
    box-shadow: 0 16px 24px rgba(var(--color-rgb-black-900), 0.08);
    border: none;
    font-size: 14px;
    @media (max-width: $mobileSize) {
       margin: 160px 24px;
     }
  }

  &.client-radius {
    .modal-content {
      border-radius: 24px;
    }
  }
}

.onboarding-help-modal {
  .modal-dialog {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 87px;
    margin: 0 auto;
  }
  .modal-content {
    @include modal-styles;
    left: 256px;
    top: 61px;
  }
}

.onboarding-profile-modal {
  .modal-dialog {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 16px;
    margin: 0 auto;
  }
  .modal-content {
    @include modal-styles;
    left: 256px;

    .modal-arrow__bottom-0 {
      bottom: -6px;
    }
  }
}

.onboarding-projects-modal {
  .modal-dialog {
    position: fixed;
    left: 0;
    right: 0;
    top: 116px;
    margin: 0 auto;
  }
  .modal-content {
    @include modal-styles;
    left: 256px;
    top: 24px;
    .modal-arrow {
      top: 53px;
    }
  }
}


.project-onboarding-step-one {
  .modal-content {
    @include modal-styles;
    top: 143px;
    left: calc(50% - 142px);
  }
}

.project-onboarding-step-two {
  .modal-content {
    @include modal-styles;
    left: 350px;
    top: 85px;
    display: none;
  }

}

.project-onboarding-step-three {
  .modal-content {
    @include modal-styles;
    left: calc(100% - 416px);
    top: 85px;
  }
}

.project-onboarding-step-health {
  .modal-content {
    @include modal-styles;
    left: calc(100% - 720px);
    top: 143px;
  }
}

.project-onboarding-step-four {
  &.pinup .modal-content {
    top: 64px;
  }
  .modal-content {
    @include modal-styles;
    left: calc(100% - 780px);
    top: 143px;
  }
}

.project-onboarding-step-five {
  .modal-content {
    @include modal-styles;
    left: calc(100% - 440px);
    top: 85px;
  }
}

.onboarding-modal {
  .modal-content {
    max-width: 480px;
    min-width: 400px;
    border-radius: 8px;
    box-shadow: 0 16px 24px rgba(var(--color-rgb-black-900), 0.08);
    border: none;
    font-size: 14px;
  }

  &-menu {
    .modal-content {
      @include modal-styles;
      left: 256px;
      top: 200px;
    }
  }

}

.onboarding-backdrop-left {
  left: 240px;
}

.client-onboarding-modal {
  .modal-dialog {
    height: 100%;
  }

  &.mobile {
    z-index: 1055;
  }

  .modal-content {
    @include modal-styles;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    position: absolute;

    @media (max-width: $mobileSize) {
      width: calc(100vw - 32px);
      max-width: 358px;
    }
  }

  &.step-one {
    .modal-content {
      transform: none;
      top: 148px;
      left: 276px;
    }

    &.mobile {
      .modal-content {
        top: 210px;
        left: 50%;
        transform: translateX(-50%);

        .modal-arrow {
          left: 50%;
          top: -6px;
          transform: translateX(-50%) rotate(945deg);
          border-radius: 0 0 2px 0;
        }
      }
    }
  }

  &.step-two {
    .modal-content {
      transform: none;
      top: unset;
      left: 256px;
      bottom: 44px;
    }

    &.mobile {
      .modal-content {
        top: unset;
        left: 50%;
        transform: translateX(-50%);
        bottom: 164px;

        .modal-arrow {
          left: 50%;
          bottom: -6px;
          transform: translateX(-50%) rotate(45deg);
          border-radius: 0px 0px 2px 0px;
        }
      }
    }
  }

  &.step-three {
    .modal-content {
      transform: none;
      top: 156px;
      left: 16px;
    }

    &.mobile {
      .modal-content {
        top: 146px;
        left: 50%;
        transform: translateX(-50%);

        .modal-arrow {
          left: 50%;
          top: -6px;
          transform: translateX(-50%) rotate(45deg);
        }
      }
    }
  }

  &.step-four {
    .modal-content {
      transform: none;
      top: 72px;
      left: unset;
      right: 52px;
    }
  }

  &.step-five {
    .modal-content {
      transform: none;
      top: 80px;
      left: unset;
      right: 16px;
    }

    &.mobile {
      .modal-content {
        top: calc(var(--onboarding-profile-top) - 288px);
        left: 50%;
        transform: translateX(-50%);
        bottom: unset;

        .modal-arrow {
          top: unset;
          left: 50%;
          bottom: -6px;
          transform: translateX(-50%) rotate(45deg);
          border-radius: 0px 0px 2px 0px;
        }
      }
      &.no {
        .modal-content {
          top: calc(var(--onboarding-profile-top) - 264px);
        }
      }
    }
  }
}

.full-screen-modal {
  .modal-dialog-centered {
    height: 100vh;

    .modal-content {
      width: calc(100vw - 96px);
      height: calc(100vh - 96px);
      border-radius: 8px;
      box-shadow: 0 16px 24px rgba(var(--color-rgb-black-900), 0.08);
      border: none;
      font-size: 14px;
    }

    .modal-body {
      height: calc(var(--viewport-height) - 165px);
      box-sizing: border-box;
      padding: 0;
      width: 100%;
    }

    swiper-container {
      height: inherit;
    }

    swiper-slide > div {
      display: flex;
      align-items: center;
      justify-content: center;
      height: calc(var(--viewport-height) - 165px);
    }

    swiper-slide img {
      max-width: 100%;
      max-height: 100%;
    }
  }
}

.full-screen-modal--image .image-preview-modal {
  .modal-body {
    height: calc(var(--viewport-height) - 165px);
    box-sizing: border-box;
    padding: 0;
    width: 100%;

    swiper-container {
      height: inherit;
    }

    swiper-slide > div {
      display: flex;
      align-items: center;
      justify-content: center;
      height: calc(var(--viewport-height) - 165px);
    }

    swiper-slide img {
      max-width: 100%;
      max-height: 100%;
    }
  }
}

.price-offer-modal {
   .modal-content {
     width: 496px;
     height: calc(var(--viewport-height) - 48px);
     max-height: 824px;
     border-radius: 8px;
     box-shadow: 0 16px 24px rgba(var(--color-rgb-black-900), 0.08);
     border: none;
     font-size: 14px;
   }
}

.summary-products-modal {
  .modal-content {
    width: 496px;
    height: auto;
    max-height: 100%;
    border-radius: 8px;
    box-shadow: 0 16px 24px rgba(var(--color-rgb-black-900), 0.08);
    border: none;
    font-size: 14px;
  }
}

.add-proxy-user {
  .modal-content {
    width: 496px;
    height: 560px;
    border-radius: 8px;
    box-shadow: 0 16px 24px rgba(var(--color-rgb-black-900), 0.08);
    border: none;
    font-size: 14px;
  }
}

.new-rule-modal {
  .modal-content {
    width: 496px;
    border-radius: 8px;
    box-shadow: 0 16px 24px rgba(var(--color-rgb-black-900), 0.08);
    border: none;
    font-size: 14px;
  }
}

.full-screen-modal {
  .modal-dialog-centered {
    height: var(--viewport-height);

    &.client {
      .modal-content {
        border-radius: 24px;
      }
    }

    .modal-content {
      width: calc(100vw - 96px);
      height: calc(100svh - 93px);
      border-radius: 8px;
      box-shadow: 0 16px 24px rgba(var(--color-rgb-black-900), 0.08);
      border: none;
      font-size: 14px;

      @media (max-width: $mobileSize) {
        width: 100%;
        height: 100%;
        border-radius: 0 !important;
      }
    }
  }
}

.unit-attribute-modal {
  .modal-content {
    width: 496px;
    box-shadow: 0 4px 24px -2px rgba(var(--color-rgb-black-830), 0.16), inset 1px 0px 0px var(--color-gray-820);
    border-radius: 8px;
    box-sizing: border-box;
  }

  &.full-screen {
    .modal-content {
      margin: 48px 0;
      max-height: calc(100vh - 96px);
    }
  }
}

.centered-modal-768 {
  height: var(--viewport-height);

  .modal-content {
    width: 100%;
    max-width: 768px;
    height: calc(100vh - 96px);

    @media (max-width: $mobileSize) {
      height: 100%;
      border-radius: 0 !important;
    }
  }

  &.admin-modal {
    .modal-content {
      border-radius: 8px;
    }
  }
}

.product-library-modal {
  .modal-content {
    width: 1024px;
    height: 724px;
  }
}

.custom-modal-construction {
  .modal-dialog {
    padding: 28px 0 48px 0;
    @media (max-width: $mobileSize) {
      padding: 0;
    }
  }

  .modal-content {
    width: calc(100vw - 2 * #{$space});
    height: calc(var(--viewport-height) - 2 * #{$space});
    border-radius: 24px;
    @media (max-width: $mobileSize) {
      width: calc(100vw - 2 * 16px);
      height: calc(var(--viewport-height) - 2 * 16px);
    }
  }
}

.transfer-modal {
  .modal-content {
    width: 496px;
    box-shadow: 0 16px 24px rgba(var(--color-rgb-black-900), 0.08);
    border-radius: 8px;
    box-sizing: border-box;
    border: none;
  }

  &.full-screen {
    .modal-content {
      margin: 48px 0;
      max-height: calc(100vh - 96px);
    }
  }

  &--contractor {
    .modal-content {
      width: 496px;
      height: 560px;
      box-shadow: 0 16px 24px rgba(var(--color-rgb-black-900), 0.08);
      border-radius: 8px;
      box-sizing: border-box;
      border: none;
    }
  }
}

.new-offer-modal {
  .modal-content {
    max-width: 496px;
    width: 100%;
    box-shadow: 0 16px 24px rgba(var(--color-rgb-black-900), 0.08);
    border-radius: 8px;
    box-sizing: border-box;
    border: none;
    margin: 0 16px;
    max-height: calc(var(--viewport-height) - 96px);
  }

  &.client-radius {
    .modal-content {
      border-radius: 24px;
    }
  }
}

.confirm-condition-modal {
  .modal-content {
    max-width: min(496px, 100vw - 32px);
    width: 100%;
    border-radius: 8px;
    box-shadow: 0 16px 24px rgba(var(--color-rgb-black-900), 0.08);
    border: none;
    font-size: 14px;
    display: flex;
    flex-direction: column;
    min-height: 0;
    max-height: 100%;
  }

  &.client-radius {
    .modal-content {
      border-radius: 24px;
    }
  }
}

.reclamation-transfer-modal {
  .modal-content {
    width: 496px;
  }
}

.new-thread-modal-client-V2 {
  .modal-content {
    height: calc(var(--viewport-height) - var(--client-height-header) - 24px * 2 - 2px);
    border-radius: 16px;
    overflow: hidden;
  }
}

.full-screen-preview {
  .modal-content {
    width: 100svw;
    height: 100svh;
    border-radius: 0;
    border: none;
  }
}

.units-import-modal {
  .modal-content {
    width: 496px;
    border-radius: 8px;
    box-shadow: 0 16px 24px rgba(var(--color-rgb-black-900), 0.08);
    border: none;
    font-size: 14px;
  }
}


.unit-address-modal {
  .modal-content {
    width: 496px;
    box-shadow: 0 16px 24px rgba(var(--color-rgb-black-900), 0.08);
    border-radius: 8px;
    box-sizing: border-box;
    border: none;
  }
}

.ng2-pdf-viewer-container {
  overflow-y: auto;
  overflow-x: hidden !important;
}

.client-options-onboarding-modal {
  .modal-dialog {
    height: 100%;
  }

  &.mobile {
    z-index: 1055;
  }

  .modal-content {
    @include modal-styles;
    border-radius: 24px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    position: absolute;

    @media (max-width: $mobileSize) {
      width: calc(100vw - 32px);
      max-width: 358px;
    }
  }

  &.step-one {
    .modal-content {
      top: 74px;
      transform: translateX(-50%);
    }

    &.mobile {
      .modal-content {
        top: 249px;
        left: 50%;
        transform: translateX(-50%);

        .modal-arrow {
          left: 50%;
          top: -6px;
          transform: translateX(-50%) rotate(945deg);
          border-radius: 0 0 2px 0;
        }
      }
    }
  }

  &.step-two {
    .modal-content {
      top: 50%;
      left: calc(100% - 479px);
      transform: translateY(-50%);
    }

    &.mobile {
      .modal-content {
        top: unset;
        left: 50%;
        transform: translateX(-50%);
        bottom: 229px;

        .modal-arrow {
          left: 50%;
          bottom: -6px;
          transform: translateX(-50%) rotate(45deg);
          border-radius: 0px 0px 2px 0px;
        }
      }
    }
  }

  &.step-three {
    .modal-content {
      top: 50%;
      left: unset;
      transform: translateY(-50%);
      right: 512px;
    }

    &.mobile {
      .modal-content {
        top: 144px;
        left: 50%;
        transform: translateX(-50%);

        .modal-arrow {
          left: 50%;
          top: -6px;
          transform: translateX(-50%) rotate(45deg);
        }
      }
    }
  }

  &.step-four {
    .modal-content {
      transform: translateX(50%);
      top: unset;
      left: unset;
      right: calc(17%);
      bottom: 88px;
    }
  }

  &.step-five {
    .modal-content {
      transform: none;
      top: 74px;
      left: unset;
      right: 16px;
    }

    &.mobile {
      .modal-content {
        top: calc(var(--onboarding-profile-top) - 288px);
        left: 50%;
        transform: translateX(-50%);
        bottom: unset;

        .modal-arrow {
          top: unset;
          left: 50%;
          bottom: -6px;
          transform: translateX(-50%) rotate(45deg);
          border-radius: 0px 0px 2px 0px;
        }
      }
      &.no {
        .modal-content {
          top: calc(var(--onboarding-profile-top) - 264px);
        }
      }
    }
  }

  &.step-six {
    .modal-content {
      transform: none;
      top: 74px;
      left: unset;
      right: 16px;
    }

    &.mobile {
      .modal-content {
        top: calc(var(--onboarding-profile-top) - 288px);
        left: 50%;
        transform: translateX(-50%);
        bottom: unset;

        .modal-arrow {
          top: unset;
          left: 50%;
          bottom: -6px;
          transform: translateX(-50%) rotate(45deg);
          border-radius: 0px 0px 2px 0px;
        }
      }
      &.no {
        .modal-content {
          top: calc(var(--onboarding-profile-top) - 264px);
        }
      }
    }
  }
}

.client-showroom-onboarding-modal {
  .modal-dialog {
    height: 100%;
  }

  .modal-content {
    @include modal-styles;
    border-radius: 24px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    position: absolute;
  }

  &.step-two {
    .modal-content {
      top: 50%;
      left: calc(100vw - 480px);
      transform: translateY(-50%);
    }
  }

  &.step-switcher {
    .modal-content {
      top: 253px;
      left: calc(50% - 255px);
    }
  }

  &.step-three {
    .modal-content {
      top: 50%;
      left: unset;
      transform: translateY(-50%);
      right: 512px;
    }
  }

  &.step-four {
    .modal-content {
      transform: translateX(50%);
      top: unset;
      left: unset;
      right: calc(17%);
      bottom: 88px;
    }
  }
}


.custom-modal--rules-list {
  max-width: 728px;
  .modal-content {
    height: calc(var(--viewport-height) - 96px);
    top: 48px;
    width: 100%;
    box-shadow: 0 16px 24px rgba(var(--color-rgb-black-900), 0.08);
    border-radius: 8px;
    box-sizing: border-box;
  }
}

.modal-rules-edit {
  & .modal-content {
    width: 496px;
    min-height: 250px;
    border-radius: 8px;

    @media (max-width: $mobileSize) {
      & {
        margin: 0 32px;
      }
    }
  }
}

.price-request-modal {
  & .modal-content {
    width: 496px;
    min-height: 250px;

    @media (max-width: $mobileSize) {
      width: 100vw;
      height: var(--viewport-height);
      border-radius: 0px;
    }
  }
}

.help-options-modal {
  .modal-content {
    width: 358px;
    border-radius: 24px;
    box-shadow: 0 16px 24px rgba(var(--color-rgb-black-900), 0.08);
    border: none;
    font-size: 14px;
    overflow: hidden;
  }
}

.no-shadow {
  .modal-content {
    box-shadow: none!important;
  }
}

.product-details-modal {
  .modal-content {
    @media (max-width: $tabletSize) {
      height: calc(var(--viewport-height) - 48px)!important;
    }

    @media (max-width: $mobileSize) {
      height: var(--viewport-height)!important;
    }
  }
}

.change-request-group {
  &.change-request-detail {
    .modal-dialog {
      right: 64px;
      padding: 118px 0 64px;
    }
  }

  .modal-dialog {
    padding: 120px 0 64px;
    height: 100%;
    box-sizing: border-box;
    width: fit-content;
    position: absolute;
    right: calc(496px + 48px + 16px);

    @media (max-width: 1040px) {
      left: 0;
      right: unset;
    }

    @media (max-width: $mobileSize) {
      padding: 0;
      right: unset;
      left: unset;
      translate: none;
      width: 100%;

      .modal-content {
        width: 100% !important;
        border-radius: 0 !important;
      }
    }

    .modal-content {
      width: 480px;
      box-shadow: 0 4px 24px -4px rgba(var(--color-gray-730), 0.2);
      border-radius: 8px;
      box-sizing: border-box;
      height: 100%
    }
  }
}

.change-request-combine-offer {
  .modal-dialog {
    padding: 48px;
    height: 100%;
    box-sizing: border-box;


    @media (max-width: $mobileSize) {
      padding: 0;
      right: unset;
      translate: none;
      width: 100%;

      .modal-content {
        width: 100% !important;
        border-radius: 0 !important;
      }
    }

    .modal-content {
      width: 496px;
      box-shadow: 0 4px 24px -4px rgba(var(--color-gray-730), 0.2);
      border-radius: 8px;
      box-sizing: border-box;
      max-height: 100%;
    }
  }
}

.br-24-modal {
  .modal-content {
    border-radius: 24px;
    min-height: unset;
  }
}
