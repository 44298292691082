.tox.tox-tinymce-aux .tox-toolbar__overflow {
  .tox-toolbar__group .tox-tbtn {
    &, & * {
      cursor: pointer !important;
    }
    width: 32px !important;
    height: 32px !important;
    border-radius: 8px !important;
    box-sizing: border-box;
    &:hover {
     background-color: var(--color-gray-650) !important;
    }
    @media (max-width: $mobileSize) {
      &:hover {
        background-color: var(--color-gray-470) !important;
       }
     }
    &:hover svg,
    & svg {
      fill: var(--color-gray-890) !important;
    }
    .tox-icon {
      transform: scale(.8);
    }
    &:first-of-type {
      &:focus {
        background-color: transparent;
      }
      &.tox-tbtn--enabled:focus {
        background-color: var(--color-gray-470);
      }
    }
    &__select-chevron {
      display: none;
    }
    &[aria-label="Font sizes"] {
      color: transparent;
      background-image: url(/assets/font_sizes.svg) !important;
      background-repeat: no-repeat;
      background-position: center;
    }

    &.tox-tbtn--select {
      font-family: 'SF Pro', serif;
      color: var(--color-gray-890) !important;
      &.tox-tbtn--bespoke {
        background-color: unset;
        width: fit-content !important;

        .tox-tbtn__select-label {
          color: var(--color-gray-890);
          font-weight: 500 !important;
          width: auto;
          margin: 0;
          cursor: inherit;
        }

        .tox-tbtn__select-chevron {
          display: none;
        }
      }
    }
  }
}
