$mobileSize: 744px;

.fade {
  transition: opacity 0s linear;
}

.fade:not(.show) {
  opacity: 0;
}

.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 10;
  display: none;
  overflow: hidden;
  outline: 0;

  user-select: text;

  svg, img {
    user-select: none!important;
    -webkit-user-drag: none!important;
  }
}

.modal-dialog {
  position: relative;
  width: 100vw;
  pointer-events: none;
}

.confirm-action-modal.modal.fade .modal-dialog, .confirm-action-modal-v2.modal.fade .modal-dialog {
  transform: translate(0, 0);
}

.modal.fade .modal-dialog.modal-dialog-centered,
.modal.fade .modal-dialog.wider-modal {
  transition: none;
}


.modal.fade .modal-dialog {
  transition: -webkit-transform 0.3s ease-out;
  transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out;
  -webkit-transform: translate(0, -25%);
  transform: translate(0, -25%);
}

@media screen and (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}

.modal.show .modal-dialog {
  -webkit-transform: translate(0, 0);
  transform: translate(0, 0);
  transition: none;
}

.modal-dialog-centered {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 24px;
  box-sizing: border-box;

  @media (max-width: $mobileSize) {
    margin: 0 auto;
    padding: 0;
  }
}

.modal-dialog-centered::before {
  display: block;
  content: '';
}

.modal-content {
  font-family: 'SF Pro', serif;
  position: relative;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: var(--color-white-1000);
  background-clip: padding-box;
  border-radius: 24px;
  outline: 0;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 10 !important;
  background-color: var(--color-black-1000);

  &.onboarding-backdrop.mobile {
    z-index: 1052!important;
  }
}

.modal-backdrop.fade {
  opacity: 0;
}

.modal-backdrop.show {
  opacity: 0.5;
}

.modal-header {
  width: calc(100vw - 143px);
  display: flex;
  -ms-flex-align: start;
  align-items: flex-start;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 1rem;
  border-bottom: 1px solid var(--color-black-600);
  border-top-left-radius: 0.3rem;
  border-top-right-radius: 0.3rem;
}

.modal-header .close {
  padding: 1rem;
  margin: -1rem -1rem -1rem auto;
}

.modal-title {
  margin-bottom: 0;
  line-height: 1.5;
}

.modal-body {
  position: relative;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  padding: 1rem;
  display: flex;
  height: 100%;
  min-height: 0;
}

.modal-footer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: .75rem;
  border-top: 1px solid #dee2e6;
  border-bottom-right-radius: calc(.3rem - 1px);
  border-bottom-left-radius: calc(.3rem - 1px);
}

.modal-footer > :not(:first-child) {
  margin-left: 0.25rem;
}

.modal-footer > :not(:last-child) {
  margin-right: 0.25rem;
}

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}

@media (min-width: 375px) {
  .modal-dialog {
    margin: 0 auto;

    &.wider-modal {
      max-width: 702px;
    }
  }
  .modal-dialog-centered {
    min-height: calc(100% - (1.75rem * 2));
  }
  .modal-dialog-centered::before {
    height: calc(100vh - (1.75rem * 2));
  }
  .modal-sm {
    max-width: 300px;
  }
}

@media (min-width: 992px) {
  .modal-lg {
    max-width: 800px;
  }
}

.d-inline-block {
  display: inline-block;
}

.d-block {
  display: block;
}

.full-screen-modal--document {
  height: 100vh;
  .modal-dialog {
    max-width: 1344px;
  }
}

.document-preview-modal {
  height: 100%;

  &.client {
    .modal-content {
      border-radius: 24px;

      .document-preview__preview {
        @media not (max-width: $mobileSize) {
          height: calc(var(--viewport-height) - 208px) !important;
        }
        @media (max-width: $mobileSize) {
          height: calc(var(--viewport-height) - 64px) !important;
        }
      }

    }
  }
  .modal-content {
    width: calc(100% - 96px);
    height: calc(100% - 96px);
    overflow: hidden;
    border-radius: 8px;

    @media (max-width: $mobileSize) {
      width: 100%;
      height: 100%;
      border-radius: 0 !important;
    }
  }
}
