/* You can add global styles to this file, and also import other style files */
body {
  overflow-y: hidden;
}

.pad-0-5 {
  padding: var(--pad-0-5);
}

.pad-1 {
  padding: var(--pad-1);
}

.pad-1-5 {
  padding: var(--pad-1-5);
}

.pad-2 {
  padding: var(--pad-2);
}

.pad-2-5 {
  padding: var(--pad-2-5);
}

.pad-3 {
  padding: var(--pad-3);
}

.pad-3-5 {
  padding: var(--pad-3-5);
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute!important;
}

.overflow-x-auto {
  overflow-x: auto;
}
