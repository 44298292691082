.d-none {
  display: none !important;
}

.d-inline {
  display: inline;
}

.d-inline-block {
  display: inline-block;
}

.d-block {
  display: block;
}

.d-table {
  display: table;
}

.d-table-row {
  display: table-row;
}

.d-table-cell {
  display: table-cell;
}

.d-flex {
  display: flex;
}

.d-inline-flex {
  display: inline-flex;
}

.flex-row {
  flex-direction: row;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-nowrap {
  flex-wrap: nowrap;
}

.flex-column {
  flex-direction: column;
}

.flex-row-reverse {
  flex-direction: row-reverse;
}

.flex-column-reverse {
  flex-direction: column-reverse;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-nowrap {
  flex-wrap: nowrap;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse;
}

.flex-fill {
  flex: 1 1 auto;
}

.flex-1 {
  flex: 1;
}

.flex-grow-0 {
  flex-grow: 0;
}

.flex-grow-1 {
  flex-grow: 1;
}

.flex-shrink-0 {
  flex-shrink: 0;
}

.flex-shrink-1 {
  flex-shrink: 1;
}

.flex-min-width-0 {
  min-width: 0;
}

.justify-content-start {
  justify-content: flex-start;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around;
}

.justify-content-evenly {
  justify-content: space-evenly;
}

.align-items-start {
  align-items: flex-start;
}

.align-items-end {
  align-items: flex-end;
}

.align-items-center {
  align-items: center;
}

.align-items-baseline {
  align-items: baseline;
}

.align-items-stretch {
  align-items: stretch;
}

.align-content-start {
  align-content: flex-start;
}

.align-content-end {
  align-content: flex-end;
}

.align-content-center {
  align-content: center;
}

.align-content-between {
  align-content: space-between;
}

.align-content-around {
  align-content: space-around;
}

.align-content-stretch {
  align-content: stretch;
}

.align-self-auto {
  align-self: auto;
}

.align-self-start {
  align-self: flex-start;
}

.align-self-end {
  align-self: flex-end;
}

.align-self-center {
  align-self: center;
}

.align-self-baseline {
  align-self: baseline;
}

.align-self-stretch {
  align-self: stretch;
}

.flex-column-centered {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.flex-stretch-mobile {
  @media (max-width: 576px) {
    flex: 1;
    display: flex;
    flex-direction: column;
  }
}

// TODO it's need to use gap property in the future
.d-flex {

  .gap-wrapper-same-shrink {
    flex-basis: 0;
    flex-grow: 1;
    min-height: 0;
    min-width: 0;
  }

  &.flex-gap-0-5 {
    margin: calc(var(--pad-0-5) / 2 * -1);
    &>* {
      padding: calc(var(--pad-0-5) / 2);
    }
  }

  &.flex-gap-1 {
    margin: calc(var(--pad-1) / 2 * -1);
    &>* {
      padding: calc(var(--pad-1) / 2);
    }
  }

  &.flex-gap-1-5 {
    margin: calc(var(--pad-1-5) / 2 * -1);

    &>* {
      padding: calc(var(--pad-1-5) / 2);

    }
  }

  &.flex-gap-2 {

    margin: calc(var(--pad-2) / 2 * -1);
    &>* {
      padding: calc(var(--pad-2) / 2);

    }
  }

  &.flex-gap-2-5 {

    margin: calc(var(--pad-2-5) / 2 * -1);
    &>* {
      padding: calc(var(--pad-2-5) / 2);

    }
  }

  &.flex-gap-3 {
    margin: calc(var(--pad-3) / 2 * -1);

    &>* {
      padding: calc(var(--pad-3) / 2);

    }
  }
}

.gap {
  &-4 {
    gap: 4px;
  }

  &-8 {
    gap: 8px;
  }

  &-12 {
    gap: 12px;
  }

  &-16 {
    gap: 16px;
  }
  
  &-24 {
    gap: 24px;
  }
}