@import '__varibles';
@import '__form';
@import 'reset';
@import 'fonts';
@import 'phone-lib';
@import 'input';
@import 'card';
@import 'button';
@import 'modal-window';
@import 'modal';
@import 'flex';
@import 'toast';
@import 'statistic-card';
@import '_dragula';
@import '_preloader-skeleton';
@import '_custom-table';
@import '_general';
@import 'spinner';
@import '_newest-document-preview.scss';
@import '_tooltip.scss';
@import '~@angular/cdk/overlay-prebuilt.css';
@import '_editor.scss';
@import 'editor-toolbar-aux.scss';

$mobileSize: 744px;

atl-left-menu-tooltip {
  display: none;
}

.element {
  @media (max-width: $mobileSize) {
    &--hidden {
      display: none!important;
    }
  }

  &--show {
    display: block;
  }

  &--mobile {
    display: none;

    @media (max-width: $mobileSize) {
      display: block;
    }

    &--inline {
      display: none;

      @media (max-width: $mobileSize) {
        display: inline;
      }
    }

    &--flex {
      display: none;

      @media (max-width: $mobileSize) {
        display: flex;
      }
    }
  }
}

.container {
  padding: 48px;
  box-sizing: border-box;

  &--admin {
    padding: 0 48px;
  }

  @media (max-width: 768px) {
    & {
      padding: 32px 24px 24px;
      min-height: 100%;
      margin: 0 auto;

      &-hidden {
        display: none;
      }
    }
  }

  @media (min-width: 577px) and (max-width: 768px) {
    & {
      width: 450px;
      margin: 0 auto;
    }
  }

  @media (min-width: 767px) and (max-width: 852px) {
    & {
      width: 500px;
      margin: 0 auto;
    }
  }

  @media (min-width: 853px) and (max-width: 992px) {
    & {
      margin: 0 auto;
    }
  }

}

.fz {
  &-11 {
    font-size: 11px;
  }

  &-12 {
    font-size: 12px;
  }

  &-14 {
    font-size: 14px;
  }

  &-15 {
    font-size: 15px;
  }

  &-16 {
    font-size: 16px;
  }

  &-17 {
    font-size: 17px;
  }

  &-18 {
    font-size: 18px;
  }

  &-20 {
    font-size: 20px;
  }

  &-24 {
    font-size: 24px;
  }

  &-28 {
    font-size: 28px;
  }

  &-32 {
    font-size: 32px;
  }

  &-40 {
    font-size: 40px;
  }
}

.fw {
  &-400 {
    font-weight: 400;
  }

  &-500 {
    font-weight: 500;
  }

  &-600 {
    font-weight: 600;
  }
}

.lh {
  &-normal {
    line-height: normal;
  }

  &-24 {
    line-height: 24px;
  }

  &-18 {
    line-height: 18px;
  }

  &-16 {
    line-height: 16px;
  }

  &-20 {
    line-height: 20px;
  }

  &-25 {
    line-height: 25px;
  }

  &-28 {
    line-height: 28px;
  }

  &-32 {
    line-height: 32px;
  }

  &-34 {
    line-height: 34px;
  }

  &-36 {
    line-height: 36px;
  }

  &-56 {
    line-height: 56px;
  }
}

.mr {
  &-54 {
    margin-right: 54px;
  }

  &-34 {
    margin-right: 34px;
  }

  &-32 {
    margin-right: 32px;
  }

  &-24 {
    margin-right: 24px;
  }

  &-22 {
    margin-right: 22px;
  }

  &-20 {
    margin-right: 20px;
  }

  &-18 {
    margin-right: 18px;
  }

  &-16 {
    margin-right: 16px;
  }

  &-14 {
    margin-right: 14px;
  }

  &-12 {
    margin-right: 12px;
  }

  &-10 {
    margin-right: 10px;
  }

  &-8 {
    margin-right: 8px;
  }

  &-6 {
    margin-right: 6px;
  }

  &-5 {
    margin-right: 5px;
  }

  &-4 {
    margin-right: 4px;
  }

  &-2 {
    margin-right: 2px;
  }

  &-0 {
    margin-right: 0 !important;
  }
}

.ml {
  &-64 {
    margin-left: 64px;
  }

  &-48 {
    margin-left: 48px;
  }

  &-40 {
    margin-left: 40px;
  }

  &-36 {
    margin-left: 36px;
  }

  &-32 {
    margin-left: 32px;
  }

  &-30 {
    margin-left: 30px;
  }

  &-28 {
    margin-left: 28px;
  }

  &-24 {
    margin-left: 24px;
  }

  &-22 {
    margin-left: 22px;
  }

  &-21 {
    margin-left: 21px;
  }

  &-18 {
    margin-left: 18px;
  }

  &-16 {
    margin-left: 16px;
  }

  &-15 {
    margin-left: 15px;
  }

  &-14 {
    margin-left: 14px;
  }

  &-12 {
    margin-left: 12px;
  }

  &-10 {
    margin-left: 10px;
  }

  &-9 {
    margin-left: 9px;
  }

  &-8 {
    margin-left: 8px;
  }

  &-6 {
    margin-left: 6px;
  }

  &-4 {
    margin-left: 4px;
  }

  &-3 {
    margin-left: 3px;
  }

  &-2 {
    margin-left: 2px;
  }

  &-0 {
    margin-left: 0 !important;
  }

}

.mt {
  &-0 {
    margin-top: 0;
  }

  &-2 {
    margin-top: 2px;
  }

  &-3 {
    margin-top: 3px;
  }

  &-4 {
    margin-top: 4px;
  }

  &-6 {
    margin-top: 6px;
  }

  &-8 {
    margin-top: 8px;
  }

  &-10 {
    margin-top: 10px;
  }

  &-11 {
    margin-top: 11px;
  }

  &-12 {
    margin-top: 12px;
  }

  &-14 {
    margin-top: 14px;
  }

  &-16 {
    margin-top: 16px;
  }

  &-18 {
    margin-top: 18px;
  }

  &-19 {
    margin-top: 19px;
  }

  &-20 {
    margin-top: 20px;
  }

  &-21 {
    margin-top: 21px;
  }

  &-22 {
    margin-top: 22px;
  }

  &-24 {
    margin-top: 24px;
  }

  &-26 {
    margin-top: 26px;
  }

  &-28 {
    margin-top: 28px;
  }

  &-32 {
    margin-top: 32px;
  }

  &-48 {
    margin-top: 48px;
  }

  &-60 {
    margin-top: 60px;
  }
}

.mb {

  &-2 {
    margin-bottom: 2px;
  }

  &-4 {
    margin-bottom: 4px;
  }

  &-8 {
    margin-bottom: 8px;
  }

  &-12 {
    margin-bottom: 12px;
  }

  &-16 {
    margin-bottom: 16px;
  }

  &-20 {
    margin-bottom: 20px;
  }

  &-22 {
    margin-bottom: 22px;
  }

  &-24 {
    margin-bottom: 24px;
  }

  &-28 {
    margin-bottom: 28px;
  }

  &-30 {
    margin-bottom: 30px;
  }

  &-32 {
    margin-bottom: 32px;
  }

  &-40 {
    margin-bottom: 40px;
  }

  &-48 {
    margin-bottom: 48px;
  }
}

.m {
  &-12 {
    margin: 12px;
  }
}

.p {

  &-28 {
    padding: 28px;
  }

  &-24 {
    padding: 24px;
  }

  &-16 {
    padding: 16px;
  }

  &-12 {
    padding: 12px;
  }

  &-8 {
    padding: 8px;
  }

  &-4 {
    padding: 4px;
  }

  &-0 {
    padding: 0 !important;
  }

}

.pr {

  &-28 {
    padding-right: 28px;
  }

  &-24 {
    padding-right: 24px;
  }

  &-16 {
    padding-right: 16px;
  }

  &-12 {
    padding-right: 12px;
  }

  &-8 {
    padding-right: 8px;
  }

}

.pl {

  &-32 {
    padding-left: 32px;
  }

  &-28 {
    padding-left: 28px;
  }

  &-24 {
    padding-left: 24px;
  }

  &-16 {
    padding-left: 16px;
  }

  &-12 {
    padding-left: 12px;
  }

  &-11 {
    padding-left: 11px;
  }

  &-8 {
    padding-left: 8px;
  }

  &-4 {
    padding-left: 4px;
  }

  &-0 {
    padding-left: 0;
  }
}

.br {
  &-4 {
    border-radius: 4px;
  }

  &-6 {
    border-radius: 6px;
  }

  &-8 {
    border-radius: 8px;
  }

  &-12 {
    border-radius: 12px;
  }

  &-16 {
    border-radius: 16px;
  }

  &-32 {
    border-radius: 32px;
  }
}

.w {

  &-auto {
    width: auto;
  }

  &-100 {
    width: 100%;
  }

  &-70 {
    width: 70%;
  }

  &-50 {
    width: 50%;
  }

  &-30 {
    width: 30%;
  }

  &-max-content {
    width: max-content !important;
  }

  &-fit-content {
    width: fit-content !important;
  }

  &-mob {

    @media (max-width: $mobileSize) {
      &-100 {
        width: 100%;
      }
    }
  }
}

.cursor-pointer {
  cursor: pointer;
}

.cursor-not-allowed {
  cursor: not-allowed;
}

.outline-none {
  outline: none;
}

.user-select-none {
  user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  -webkit-user-select: none;
}

.user-select-text {
  user-select: text;
  -moz-user-select: text;
  -ms-user-select: text;
  -o-user-select: text;
  -webkit-user-select: text;
}

.h {
  &-100 {
    height: 100%;
  }

  &-90 {
    height: 90%;
  }

  &-70 {
    height: 70%;
  }

  &-60 {
    height: 60%;
  }

  &-px {
    &-16 {
      height: 16px;
      min-height: 16px;
    }

    &-24 {
      height: 24px;
      min-height: 24px;
    }

    &-32 {
      height: 32px;
      min-height: 32px;
      border-radius: 16px;
      padding: 0 12px;
    }

    &-40 {
      height: 40px;
      min-height: 40px;
      border-radius: var(--button-border-radius);
    }
  }
}

.white-space-nowrap {
  white-space: nowrap;
}

.white-space-prewrap {
  white-space: pre-wrap;
}

.f-family {
  &-sfpro {
    font-family: 'SF Pro', serif;
  }

}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height .35s ease;
}


.header-admin {
  &__title {
    font-size: 20px;
    line-height: 24px;
    font-weight: 500;
    color: var(--primary-text-color);
  }
}

.overflow-hidden {
  overflow: hidden !important;
}

.fit-content {
  width: fit-content;
}

.v-scrollbar {
  overflow-x: hidden;
  overflow-y: -moz-scrollbars-vertical;
  overflow-y: auto;

  &::-webkit-scrollbar {
    height: 30px;
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    width: 2px;
  }

  &::-webkit-scrollbar-thumb {
    background-clip: padding-box;
    background: var(--color-black-750);
    border-bottom: 4px transparent solid;
    border-radius: 5px;
    border-top: 4px transparent solid;
  }

  &.small {
    &::-webkit-scrollbar {
      width: 4px;
    }
  }

  //FIREFOX
  scrollbar-color: var(--color-black-750) transparent;
  scrollbar-width: thin;

  &--hidden {
    &::-webkit-scrollbar {
      width: 0;
    }

    &::-webkit-scrollbar-track {
      width: 0;
    }

    scrollbar-width: none !important;
  }
}

// Note: limited support. Supported only on webkit browsers.
.overlay {
  overflow: overlay;
}

.overlay-y {
  overflow-y: overlay;
}

.h-scrollbar {
  overflow-y: hidden;
  overflow-x: -moz-scrollbars-horizontal;
  overflow-x: auto;

  &::-webkit-scrollbar {
    width: 30px;
    height: 2px;
  }

  &::-webkit-scrollbar-track {
    height: 2px;
  }

  &::-webkit-scrollbar-thumb {
    background-clip: padding-box;
    background: var(--color-black-750);
    border-left: 1px transparent solid;
    border-right: 1px transparent solid;
    border-radius: 1px;
  }

  //FIREFOX
  scrollbar-color: var(--color-black-750) transparent;
  scrollbar-width: thin;

  &--hidden {
    &::-webkit-scrollbar {
      height: 0;
    }

    &::-webkit-scrollbar-track {
      height: 0;
    }

    scrollbar-width: none;
  }
}

.scrollbar {
  overflow-x: -moz-scrollbars-horizontal;
  overflow-x: auto;
  overflow-x: overlay;
  overflow-y: -moz-scrollbars-vertical;
  overflow-y: auto;
  overflow-y: overlay;

  &::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }

  &::-webkit-scrollbar-track {
    width: 2px;
    height: 2px;
  }

  &::-webkit-scrollbar-thumb {
    background-clip: padding-box;
    background: var(--color-black-750);
    border-left: 1px transparent solid;
    border-right: 1px transparent solid;
    border-radius: 1px;
  }

  scrollbar-color: var(--color-black-750) transparent;
  scrollbar-width: thin;

  &--hidden {
    &::-webkit-scrollbar {
      height: 0;
    }

    &::-webkit-scrollbar-track {
      height: 0;
    }

    scrollbar-width: none;
  }
}

.pb-0 {
  padding-bottom: 0;
}

.pb {
  &-24 {
    padding-bottom: 24px;
  }

  &-16 {
    padding-bottom: 16px;
  }

  &-12 {
    padding-bottom: 12px;
  }

  &-8 {
    padding-bottom: 8px;
  }

  &-4 {
    padding-bottom: 4px;
  }
}



.pt {
  &-0 {
    padding-top: 0;
  }

  &-4 {
    padding-top: 4px;
  }

  &-8 {
    padding-top: 8px;
  }

  &-16 {
    padding-top: 16px;
  }

  &-24 {
    padding-top: 24px;
  }
}


atl-button-expanded-dropdown.table-header-buttons {
  .button-expanded-dropdown__dropdown-content {
    padding: 6px var(--pad-0-5);
  }

  .table-header-button {
    align-items: center;
    border: none;
    background: transparent;
    color: var(--color-black-900);
    display: flex;
    flex-grow: 1;
    font-family: 'SF Pro', serif;
    font-size: 14px;
    font-style: normal;
    font-weight: normal;
    height: 40px !important;
    justify-content: flex-start;
    box-sizing: border-box;
    padding: 1px 5px;

    &:hover {
      background: var(--color-gray-450);
      border-radius: 8px;
      cursor: pointer;
    }
  }
}

.text-align-center {
  text-align: center;
}

.text-align-left {
  text-align: left;
}

.text-align-right {
  text-align: right;
}

.uppercase {
  text-transform: uppercase;
}

.text-transform-initial {
  text-transform: initial;
}

.ellipsis {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.ellipsis-card {
  position: relative;
}

.ellipsis-card:before {
  content: '&nbsp;';
  visibility: hidden;
}

.ellipsis-item {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.ellipsis-second-line {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden !important;
  text-overflow: ellipsis;
  word-break: break-word;
}

.auth-admin {
  display: flex;
  width: 100vw;
  height: var(--viewport-height);

  &__tag {
    box-sizing: border-box;
    padding: 4px 8px;
    background-color: var(--color-blue-980);
    border-radius: 32px;
    color: var(--color-white-1000);
  }

  &__content {
    height: var(--viewport-height);
    width: 50%;
    background: var(--color-white-1000);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    padding: 0 24px;
    position: relative;

    @media (max-width: $mobileSize) {
      & {
        width: 100%;
        padding: 0 32px;
      }
    }

    &-title {
      font-weight: 500;
      font-size: 24px;
      line-height: 32px;
      color: var(--primary-text-color);
      text-align: left;
    }

    &-subtitle {
      text-align: left;

      span {
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        color: var(--color-gray-890);

        &.single-span {
          color: var(--color-gray-890) !important;
          cursor: default !important;
        }

        &:last-child {
          cursor: pointer;
          color: var(--color-blue-980);
        }
      }
    }

    &-input {
      atl-input {
        .label {
          font-weight: 600;
          font-size: 14px;
          line-height: 16px;
          color: var(--color-gray-890);
          text-transform: none;
        }

        .global_input {
          height: 40px;
          border-radius: 8px;
          font-weight: 400;
          font-size: 16px;
          line-height: 24px;
          color: var(--primary-text-color);

          &::placeholder {
            color: var(--color-gray-902);
          }

          &:focus {
            border: 1px solid var(--color-blue-980);
            box-shadow: 0 0 0 2px var(--color-gray-810);
          }

          .link {
            font-weight: 500;
            font-size: 12px;
            line-height: 16px;
            color: var(--color-blue-980);
          }
        }

        .input__show-password-icon {
          width: 16px;
          height: 16px;
          top: 36px;

          &:hover {
            filter: var(--filter-black-960);
          }
        }
      }

      atl-drop-down-writing {
        &.ng-select-focused > .ng-select-container {
          border: 1px solid var(--color-blue-980);
        }
        .label {
          font-weight: 600;
          font-size: 14px;
          line-height: 16px;
          color: var(--color-gray-890);
          text-transform: none;
        }

        .ng-select-container {
          padding: 8px 8px 8px 0;
          height: 40px;
          border-radius: 4px;

          .ng-value-container {
            overflow: visible;

            .ng-value {
              overflow: visible;
              line-height: 18px;
            }
          }

          .ng-arrow-wrapper {
            width: 16px;
            margin-right: 0;
            .ng-arrow {
              background-image: url(/assets/arrow_down_option_store.svg);
              background-repeat: no-repeat;
              background-position: center;
              right: 0;
            }
          }
        }
        .ng-option:not(:first-child) {
          margin-top: 4px;
        }
      }

      .global-caption--wrapper:empty {
        height: 0;
      }

      .global-caption--wrapper:not(:empty) {
        margin-top: 8px;
      }

      &:not(:first-of-type) {
        margin-top: 24px;
        @media (max-width: $mobileSize) {
          margin-top: 16px;
        }
      }
    }

    &-button {
      margin-top: 32px;

      button {
        width: 100%;
        height: 40px;
        border-radius: 32px;
        background: var(--color-gray-815);
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        white-space: nowrap;
        color: var(--color-blue-980);
      }
    }

    .term {
      display: flex;
      flex-direction: column;
      font-weight: 500;
      font-size: 12px;
      line-height: 16px;
      color: var(--color-blue-980);
      cursor: pointer;
      width: min-content;
      text-decoration: unset;
      white-space: nowrap;

      &__border {
        width: 100%;
        background: var(--color-blue-980);
        opacity: 0.3;
        border: 1px solid var(--color-blue-980);
        border-radius: 5px;
      }
    }
  }

  &__logo {
    margin-bottom: 24px;

    &-container {
      width: 100%;
      max-width: 430px;
    }
  }

  &__cover {
    height: var(--viewport-height);
    width: 50%;
    box-sizing: border-box;
    padding: 16px;
    background-image: url('/assets/auth_cover_admin.jpeg');
    background-size: cover;
    background-position: right 35% bottom 45%;

    .cover {
      width: 100%;
      height: 100%;
      background-size: cover;
      background-position: right 35% bottom 45%;
      border-radius: 16px;
    }

    &--client {
      background-image: unset;
      .cover {
        background-image: url('/assets/auth-cover_v2.jpg');
      }

      @media (max-width: $mobileSize) {
        & {
          display: none;
        }
      }
    }

    @media (max-width: $mobileSize) {
      & {
        display: none;
      }
    }
  }
}

.action-message-banner_component {
  position: absolute;
  bottom: 24px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 100;
}


.task-status {
  display: flex;
  min-width: 0;
  position: relative;
  padding: 0px 8px 0px 24px;
  border-radius: 16px;
  font-family: 'SF Pro', serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: var(--color-black-985);

  &.table {
    padding: 0 8px 0 24px;
    height: 24px;

    &::before {
      top: 8px;
    }
  }

  &::before {
    content: '';
    top: 8px;
    left: 8px;
    position: absolute;
    width: 8px;
    height: 8px;
    border-radius: 50%;
  }

  &.uncompleted {
    background: var(--color-gray-735);
    color: var(--primary-text-color);

    &::before {
      background: var(--color-gray-890);
    }
  }

  &.completed {
    background-color: var(--color-green-200);
    color: var(--color-gray-897);

    &::before {
      background: var(--color-green-897);
    }
  }

  &.urgent {
    background: var(--color-yellow-600);
    color: var(--color-yellow-2000);

    &::before {
      background: var(--color-yellow-1100);
    }
  }

  &.past_due {
    background-color: var(--color-red-500);
    color: var(--color-black-985);

    &::before {
      background: var(--color-red-780);
    }
  }
}

.tox-toolbar__overflow .tox-tbtn:not(:first-of-type) {
  margin-left: 2px;
}

.min {
  &-w {
    &-0 {
      min-width: 0;
    }
  }

  &-h {
    &-0 {
      min-height: 0;
    }
  }
}

.above-overlay {
    position: relative;
    z-index: 11;
    pointer-events: none;
}

.dropdown-menu {
  &.client-settings-members {
    position: relative !important;
    right: 50px;
    bottom: 10px;
  }
  &.attendees-dropdown-menu {
    position: relative !important;
    right: 0px;
    &.bottom {
      bottom: 12px;
    }
    &.top {
      top: 12px;
    }
  }
}

.custom-overlay-width {
  width: 0 !important;
}

.option-stores-dropdown {
  border: 1px solid var(--color-gray-805) !important;
  box-shadow: 0 16px 24px rgba(var(--color-gray-730), 0.08) !important;
  border-radius: 4px !important;

  &__items {
    padding: 8px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    row-gap: 8px;
    width: 248px;
    height: auto;

    .dropdown-item {
      width: 100% !important;
      border-radius: 4px;
      padding: 4px 8px;
      box-sizing: border-box;
      margin: 0 !important;

      &:hover {
        background: var(--color-gray-735);
      }
    }

    &:last-child {
      border-top: 1px solid var(--color-gray-805);
    }
  }
}

.phase-datepicker {
  width: 284px;
  box-sizing: border-box;
  border-radius: 8px;
  border: 1px solid var(--color-gray-525);
  background: var(--color-white-1000);
  box-shadow: 0 4px 12px 0 rgba(var(--color-gray-730), 0.08);
  padding: 16px 0 !important;

  ngb-datepicker-navigation {
    height: 24px;

    .ngb-dp-navigation-chevron {
      border-width: 1px 1px 0 0;
      height: 8px;
      width: 8px;
      color: var(--color-gray-920);
    }

    .ngb-dp-arrow-btn {
      outline: none;
    }
  }

  .ngb-dp-header {
    padding: 0 16px !important;
    margin-bottom: 12px;

    .ngb-dp-month-name {
      font-family: 'SF Pro', serif;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 24px;
      align-items: center;
      color: var(--color-gray-890);
      display: flex
    }
  }

  .ngb-dp-content {
    padding: 0 16px !important;
  }

  .ngb-dp-day,
  .ngb-dp-week-number,
  .ngb-dp-weekday {
    margin: 2px;
  }

  .ngb-dp-weekday {
    font-family: 'SF Pro', serif;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 32px;
    color: var(--color-gray-890);
  }

  .ngb-dp-weekdays {
    border-bottom: none;
  }

  .ngb-dp-day {
    font-family: 'SF Pro', serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    color: var(--primary-text-color);


    .custom-day {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;

      &.range, &.focused, &.selected, &:hover {
        border-radius: 4px;
        background-color: var(--color-blue-1000);
        color: var(--color-white-1000);
      }

      &.faded {
        background-color: var(--color-gray-815) !important;
        color: var(--color-blue-925) !important;
      }
    }

    .outside, &.disabled {
      opacity: 1 !important;
      color: var(--color-gray-890);
    }
  }

  .ngb-dp-today {
    border-bottom: 1px solid var(--color-blue-1000);
  }

  .ngb-dp-month:first-child .ngb-dp-week {
    padding-left: 0;
  }

  .ngb-dp-month:last-child .ngb-dp-week {
    padding-right: 0;
  }
}

.shared-page-title {
  color: var(--primary-text-color);
  font-size: 24px;
  font-weight: 500;
  line-height: 32px;
}
