$mobileSize: 768px;

.task-stat {
  padding: 16px;
  height: 96px;
  box-sizing: border-box;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;

  @media (max-width: 1040px) {
    flex-wrap: wrap;
  }

  @media (max-width: $mobileSize) {
    & {
      width: 100%;
      max-width: 100%;
    }
  }

  @media (max-width: 700px) {
    flex-wrap: wrap;
  }

  @media (max-width: 576px) {
    height: auto;

    &:not(:first-child) {
      background-color: red;
      display: none;
    }
  }

  &__main {
    display: flex;
    flex-direction: column;
  }

  &__name {
    font-size: 14px;
    line-height: 24px;
    font-family: 'SF Pro', serif;
    font-weight: 500;
    color: var(--color-black-850);

    @media (max-width: 576px) {
      & {
        font-size: 12px;
        line-height: 16px;
      }
    }
  }

  &__value {
    font-family: 'SF Pro', serif;
    font-weight: 600;
    font-size: 20px;
    line-height: 40px;
    color: var(--color-black-900);
    user-select: none;

    @media (max-width: $mobileSize) {
      & {
        display: block;
      }
    }

    @media (max-width: 576px) {
      & {
        display: none;
        font-size: 24px;
        line-height: 32px;
      }
    }
  }

  &__progress {
    margin-top: 16px;
  }

  &__chart {

    &.checkbox-box {
      display: flex;
      align-items: flex-end;
      padding-bottom: 8px;
    }
  }
}
