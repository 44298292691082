.form {
  padding: 24px 48px;
  height: calc(100vh - 175px);
  overflow-y: auto;

  &__control:not(:first-child) {
    margin-top: 28px;
  }

  &__control {
    &--text-editor {
      margin-top: 10px;
      border: 1px solid var(--color-gray-750);
      border-radius: 12px;
      font-family: 'SF Pro', serif !important;
      max-height: 500px;
      height: auto;
      overflow: auto;
    }
  }
}
