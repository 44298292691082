@mixin animation {
  animation-delay: 0.25s;
  animation-direction: normal;
  animation-duration: 0.85s;
  animation-iteration-count: infinite;
  animation-name: skeleton;
  background: linear-gradient(90deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.1));
  bottom: 0;
  content: '';
  display: block;
  height: 100%;
  position: absolute;
  top: 0;
  width: 35px;
  z-index: 2;
}

@mixin skeleton {
  background: var(--color-gray-650);
  border-radius: 12px;
  color: transparent !important;
  content: "";
  position: relative;
}

.preloader-skeleton {
  cursor: default;
  pointer-events: none;
  user-select: none;

  @keyframes skeleton {
    from {
      left: 0;
    }
    95% {
      left: 95%;
    }
    to {
      right: 0;
    }
  }


  atl-input {
    .global-label {
      &:not(.filled) {
        visibility: hidden;

        &:after {
          background: var(--color-gray-650);
          border-radius: 12px;
          border: none;
          bottom: 0;
          content: "";
          display: block;
          height: 100%;
          left: 0;
          position: absolute;
          right: 0;
          top: 0;
          visibility: visible;
        }
      }
    }
  }

  .button-expanded-dropdown__wrapper,
  .multi-filter__wrapper {
    visibility: hidden;
    &:after {
      background: var(--color-gray-650);
      border-radius: 12px;
      border: none;
      bottom: 0;
      content: "";
      display: block;
      height: 100%;
      left: 0;
      position: absolute;
      right: 0;
      top: 0;
      visibility: visible;
    }
  }

  .atl-table {

    &__cell-text {
      background: var(--color-gray-650);
      border-radius: 12px;
      color: transparent;
      content: "";
      display: block;
      height: 24px;
      margin-right: 16px;
      max-width: 207px;
      position: relative;
      overflow: hidden;
      width: 100%;

      &:after {
        @include animation
      }
    }

    &__header-title {
      background: var(--color-gray-650);
      border-radius: 12px;
      color: transparent;
      content: "";
      display: block;
      height: 16px;
      transform: matrix(-1, 0, 0, 1, 0, 0);
      width: 100%;

      &:after {
        @include animation
      }
    }

    &__preview-image {
      .relative-wrapper {
        visibility: hidden;
        &:after {
          background-image: url(/assets/no_images-secondary.png);
          background-repeat: no-repeat;
          background-size: contain;
          bottom: 0;
          content: "";
          display: block;
          height: 100%;
          left: 0;
          position: absolute;
          right: 0;
          top: 0;
          visibility: visible;
        }
      }

    }
  }

  atl-row {
    atl-cell {
      &:nth-child(2) {
        .atl-table__cell-text {
          &:after {
            animation-delay: 0.5s;
            animation-duration: 0.25s;
          }
        }
      }
      &:nth-child(3) {
        .atl-table__cell-text {
          &:after {
            animation-delay: 0.75s;
            animation-duration: 0.25s;
          }
        }
      }
      &:nth-child(4) {
        .atl-table__cell-text {
          &:after {
            animation-delay: 0.1s;
            animation-duration: 0.25s;
          }
        }
      }
      &:nth-child(5) {
        .atl-table__cell-text {
          &:after {
            animation-delay: 1.25s;
            animation-duration: 0.25s;
          }
        }
      }
      &:nth-child(6) {
        .atl-table__cell-text {
          &:after {
            animation-delay: 1.5s;
            animation-duration: 0.25s;
          }
        }
      }
      &:nth-child(7) {
        .atl-table__cell-text {
          &:after {
            animation-delay: 1.75s;
            animation-duration: 0.25s;
          }
        }
      }
    }
  }

  .pagination__content {
    atl-default-dropdown {
      .pagination__wrapper {
        visibility: hidden;

        &:after {
          background: var(--color-gray-650);
          border-radius: 12px;
          border: none;
          bottom: 0;
          content: "";
          display: block;
          height: 100%;
          left: 0;
          position: absolute;
          right: 0;
          top: 0;
          visibility: visible;
        }
      }
    }
  }

  .multi-filter__category-item {
      visibility: hidden;

      & atl-titled-checkbox, & .multi-filter__category-name {
        display: none;
      }

      &:after {
        background-color: var(--color-gray-650);
        border-radius: 8px;
        color: transparent;
        content: "";
        display: block;
        height: 16px;
        margin-right: 7px;
        max-width: 207px;
        position: relative;
        overflow: hidden;
        width: 100%;
        visibility: visible;
      }
  }

  .survey {
    &__title {
      @include skeleton;
      background: var(--color-gray-875);
      display: block;
      height: 32px;
      width: 100px;
    }

    &__action {
      atl-button-expanded-dropdown {
        @include skeleton;
        background: var(--color-gray-875);
        display: block;
        height: 40px;
        overflow: hidden;
        width: 160px;

        .button-expanded-dropdown__wrapper:after {
          background: var(--color-gray-875);
        }
      }
    }
  }

  .survey-card {
    &__title {
      display: contents;

      span {
        @include skeleton;
        height: 24px !important;

        &:last-child {
          width: 40% !important;
          margin: 4px 24px 0 24px !important;
        }

        &:after {
          @include animation
        }
      }
    }

    &__chart {
      .chart {
        &__title {
          @include skeleton;
          display: block;
          height: 16px;
          margin-top: 10px;
          margin-bottom: 4px !important;
          width: 100%;

          &:after {
            @include animation
          }
        }

        &__count {
          @include skeleton;
          display: block;
          width: 46px;
          height: 32px;

          &:after {
            @include animation;
          }
        }

        &__content {
          atl-progress-circle {
            .circle-wrap {
              background-color: var(--color-gray-650);

              .circle {
                .mask .fill {
                  background-color: var(--color-gray-650);
                }
              }
            }
          }
        }
      }
    }

    &__chart-draft {
      atl-custom-icon {
        span {
          svg {
            path {
              stroke: var(--color-gray-750);
            }

            path:first-child {
              fill: var(--color-gray-750);
            }
          }
        }
      }
    }

    &__footer {
      span {
        @include skeleton;
        display: block;
        height: 24px;
        width: 77px;

        &:last-child {
          width: 44px;
        }

        &:after {
          @include animation;
        }
      }
    }
  }

  .buyers {
    &__search {
      atl-input {
        div {
          &:not(.filled) {
            @include skeleton;
            height: 32px;
            visibility: visible !important;

            &:after {
              @include animation;
            }
          }

          .global-label__icon {
            visibility: hidden !important;
          }

          input {
            visibility: hidden;
          }
        }
      }
    }

    &__control-button {
      button {
        @include skeleton;
        width: 111px;
        height: 32px;
        display: block;

        &:after {
          @include animation;
        }
      }
    }

    &__header-title {
      @include skeleton;
      width: 37px;
      height: 16px;
      display: block;

      &:after {
        @include animation;
      }
    }

    &__cell-name,
    &__cell-email,
    &__cell-company,
    &__cell-type,
    &__cell-role,
    &__cell-status {
      @include skeleton;
      height: 24px;
      display: block;

      &:after {
        @include animation;
      }
    }

    &__cell-name {
      width: 135px !important;
    }
    &__cell-email {
      width: 198px !important;

      .phone {
        display: none !important;
      }
    }
    &__cell-company {
      width: 107px !important;
    }
    &__cell-type {
      width: 77px !important;
    }
    &__cell-role {
      width: 53px !important;
    }
    &__cell-status {
      width: 87px !important;
    }
  }

  atl-default-dropdown {
    @include skeleton;

    &:after {
      @include animation;
    }
  }
}
