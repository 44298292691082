//@see https://ng-bootstrap.github.io/#/components/tooltip/api

$zindex-tooltip: 1070 !default;
$tooltip-margin: 0 !default;
$tooltip-font-size: 12px !default;

$tooltip-max-width: 220px !default;
$tooltip-color: var(--color-white-1000) !default;
$tooltip-bg: var(--color-black-971) !default;
$tooltip-border-radius: 4px !default;
$tooltip-client-border-radius: 12px !default;
$tooltip-opacity: 1 !default;
$tooltip-padding-y: 8px !default;
$tooltip-padding-x: 12px !default;
$tooltip-margin: 0 !default;

$tooltip-arrow-width: 0.8rem !default;
$tooltip-arrow-height: 0.4rem !default;
$tooltip-arrow-color: var(--color-black-971) !default;

@mixin reset-text {
  // We deliberately do NOT reset font-size or word-wrap.
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  text-align: left; // Fallback for where `start` is not supported
  text-align: start; // stylelint-disable-line declaration-block-no-duplicate-properties
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
}

.tooltip {
  position: absolute;
  z-index: $zindex-tooltip;
  display: block;
  margin: $tooltip-margin;
  // Our parent element can be arbitrary since tooltips are by default inserted as a sibling of their target element.
  // So reset our font and text properties to avoid inheriting weird values.
  @include reset-text();
  font-size: $tooltip-font-size;
  letter-spacing: 0.15px;
  // Allow breaking very long words so they don't overflow the tooltip's bounds
  word-wrap: break-word;
  opacity: 0;
  will-change: unset !important;

  &.show {
    opacity: $tooltip-opacity;
    filter: drop-shadow(0px 4px 8px rgba(var(--color-gray-730), 0.24));
  }

  .tooltip-arrow {
    position: absolute;
    display: block;
    width: $tooltip-arrow-width;
    height: $tooltip-arrow-height;

    &::before {
      position: absolute;
      content: '';
      border-color: transparent;
      border-style: solid;
    }
  }

  &.client {
    .tooltip-inner {
      border-radius: $tooltip-client-border-radius;
    }
  }

  &.change-request-offers {
    &.button {
      transform: translate3d(-72px, 56px, 0px) !important;

      .tooltip-arrow {
        transform: unset !important;
        left: unset !important;
        right: 4px;
      }
      .tooltip-inner {
        max-width: 166px;
      }
    }

    &.checkbox {
      left: 6px !important;

      .tooltip-inner {
        max-width: 160px;
      }
    }

    .tooltip-inner {
      box-sizing: border-box;
    }
  }
}

.bs-tooltip-top {
  padding: $tooltip-arrow-height 0;
  margin-bottom: 5px !important;

  .tooltip-inner {
    box-sizing: border-box;
    border: 1px solid rgba(var(--color-rgb-white-1000), 0.1);
    box-shadow: 0 4px 8px 0 rgba(var(--color-gray-730), 0.24);
  }

  &.attachment {
    &-right {
      .tooltip-inner {
        transform: translateX(130px);
        max-width: 300px;
        width: 300px;
      }
    }

    &-left {
      .tooltip-inner {
        transform: translateX(-85px);
        max-width: 190px;
        width: 190px;
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
      }
    }
  }

  &.schedule-tooltip {
    inset: auto auto 5px 0 !important;

    .tooltip-inner {
      white-space: nowrap;
      transform: translateX(-41%);
    }
  }

  .tooltip-arrow {
      border-right: 1px solid rgba(var(--color-rgb-white-1000), 0.1);
      border-bottom: 1px solid rgba(var(--color-rgb-white-1000), 0.1);
      width: 11px;
      height: 11px;
      border-radius: 0 0 3px 0;
      background: $tooltip-bg;
      bottom: 1px;
      left: 50% !important;
      transform: rotate(45deg) translate(-40%, 5px) !important;

    &::before {
      top: 0;
      border-width: $tooltip-arrow-height calc($tooltip-arrow-width / 2) 0;
      border-top-color: $tooltip-arrow-color;
    }
  }
}

.bs-tooltip-right {
  padding: 0 $tooltip-arrow-height;
  margin-left: 5px !important;

  .tooltip-arrow {
    border-right: 1px solid rgba(var(--color-rgb-white-1000), 0.1);
    border-bottom: 1px solid rgba(var(--color-rgb-white-1000), 0.1);
    width: 11px;
    height: 11px;
    border-radius: 0 0 3px 0;
    background: $tooltip-bg;
    bottom: 1px;
    top: 50% !important;
    transform: translate(-10px, -50%) !important;

    &::before {
      right: 0;
      border-width: calc($tooltip-arrow-width / 2) $tooltip-arrow-height calc($tooltip-arrow-width / 2) 0;
      border-right-color: $tooltip-arrow-color;
    }
  }
}

.bs-tooltip-end {
  padding: 0 $tooltip-arrow-height;
  margin-left: 5px !important;

  .tooltip-arrow {
    border-left: 1px solid rgba(var(--color-rgb-white-1000), 0.1);
    border-bottom: 1px solid rgba(var(--color-rgb-white-1000), 0.1);
    width: 11px;
    height: 11px;
    border-radius: 0 0 0 3px;
    background: var(--color-black-971);
    bottom: 50%;
    top: 50% !important;
    transform: rotate(45deg) translate(-8px, -3%) !important;

    &::before {
      right: 0;
      border-width: calc($tooltip-arrow-width / 2) $tooltip-arrow-height calc($tooltip-arrow-width / 2) 0;
      border-right-color: $tooltip-arrow-color;
    }
  }
}

.bs-tooltip-bottom {
  padding: $tooltip-arrow-height 0;
  margin-top: 5px !important;

  .tooltip-arrow {
    top: 0;

    &::before {
      bottom: 0;
      border-width: 0 calc($tooltip-arrow-width / 2) $tooltip-arrow-height;
      border-bottom-color: $tooltip-arrow-color;
    }
  }
}

.bs-tooltip-bottom-start {
  .tooltip-inner {
    transform: translateX(-4px);
  }
}

.bs-tooltip-top-end {
  .tooltip-arrow {
    left: unset !important;
    right: -4px;
  }
  .tooltip-inner {
    transform: translateX(4px);
  }
}

.bs-tooltip-left {
  padding: 0 $tooltip-arrow-height;
  margin-right: 5px !important;

  .tooltip-arrow {
    right: 0;
    width: $tooltip-arrow-height;
    height: $tooltip-arrow-width;

    &::before {
      left: 0;
      border-width: calc($tooltip-arrow-width / 2) 0 calc($tooltip-arrow-width / 2) $tooltip-arrow-height;
      border-left-color: $tooltip-arrow-color;
    }
  }

  &.drawing-panel {
    margin-right: 4px;
  }
}

.bs-tooltip-start {
  padding: 0 $tooltip-arrow-height;
  margin-right: 5px !important;

  .tooltip-arrow {
    right: 0;
    width: $tooltip-arrow-height;
    height: $tooltip-arrow-width;

    &::before {
      left: 0;
      border-width: calc($tooltip-arrow-width / 2) 0 calc($tooltip-arrow-width / 2) $tooltip-arrow-height;
      border-left-color: $tooltip-arrow-color;
    }
  }

  &.drawing-panel {
    margin-right: 4px !important;
  }
}

.bs-tooltip-auto {
  &[x-placement^='top'] {
    @extend .bs-tooltip-top;
  }
  &[x-placement^='right'] {
    @extend .bs-tooltip-right;
  }
  &[x-placement^='bottom'] {
    @extend .bs-tooltip-bottom;
  }
  &[x-placement^='left'] {
    @extend .bs-tooltip-left;
  }
}

// Wrapper for the tooltip content
.tooltip-inner {
  max-width: $tooltip-max-width;
  padding: $tooltip-padding-y $tooltip-padding-x;
  color: $tooltip-color;
  text-align: center;
  background-color: $tooltip-bg;
  border-radius: $tooltip-border-radius;
}

.tooltip-120 {
  max-width: 120px;
}

// Tooltip context TemplateRef
.tooltip-title {
  text-align: left;
}
.tooltip-ul {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  box-sizing: border-box;
  padding-left: 16px;
  li {
    font-size: 12px;
    list-style-type: disc;
    text-align: left;
  }
}

.tooltip-max-width-auto {
  .tooltip-inner {
    max-width: unset;
  }
}

.options-actions-unsold {
  .tooltip-inner {
    max-width: 130px;
  }
}

.units-dual-view {
  .tooltip-inner {
    max-width: 168px;
  }
}

@media (pointer: coarse) and (max-width: $mobileSize) {
  ngb-tooltip-window.touch-hide-tooltip {
    display: none !important;
  }
}

.admin-tooltip-header {
  &.bs-tooltip-bottom {
    margin-top: 4px !important;
  }
  &.bs-tooltip-top {
    margin-bottom: 4px !important;
  }
  &.bs-tooltip-start {
    margin-right: 4px !important;
  }
  &.bs-tooltip-end {
    margin-left: 4px !important;
  }
}
