$mobileSize: 768px;

// Text styles
.uppercase {
  text-transform: uppercase;
}

.capitalize {
  text-transform: capitalize;
}

.lowercase {
  text-transform: lowercase;
}

.word-break-all {
  word-break: break-all;
}

.word-break {
  word-break: break-word;
}

.underline {
  text-decoration-line: underline;
  text-underline-offset: 4px;
}

.no-border {
  border: unset !important;
}

.fill-available {
  width: -webkit-fill-available !important;
  width: stretch;
}

.height-available {
  height: -webkit-fill-available;
}

.border-box {
  box-sizing: border-box;
}


// Utilities

.text-decoration-none {
  text-decoration: none;;
}

.cursor {
  &-default {
    cursor: default!important;
  }

  &-pointer {
    cursor: pointer;
  }

  &-grab {
    cursor: grab;
  }
}

.white-space {
  &-nowrap {
    white-space: nowrap !important;
  }

  &-normal {
    white-space: normal !important;
  }

  &-break-spaces {
    white-space: break-spaces !important;
  }
}

.invisible {
  visibility: hidden;
}

.pointer-events {
  &-none {
    pointer-events: none;
  }
  &-all {
    pointer-events: all;
  }
}

.overflow-y-hidden {
  overflow-y: hidden !important;
}

.object-fit {
  &-cover {
    object-fit: cover;
  }
  &-contain {
    object-fit: contain;
  }
}

.z-index {
  &-0 {
    z-index: 0;
  }
  &-1 {
    z-index: 1;
  }
  &-2 {
    z-index: 2;
  }
  &-3 {
    z-index: 3;
  }
  &-4 {
    z-index: 4;
  }
  &-5 {
    z-index: 5;
  }
  &-6 {
    z-index: 6;
  }
  &-7 {
    z-index: 7;
  }
  &-8 {
    z-index: 8;
  }
  &-9 {
    z-index: 9;
  }
  &-10 {
    z-index: 10;
  }
  &-20 {
    z-index: 20;
  }
}

.position-absolute {
  position: absolute!important;
}

.text-editor-fz {
  .input-file {
    &__wrapper {
      right: -252px !important;
    }
  }
}

.no-border {
  border: unset !important;
}

.rotate {
  &-180 {
    transform: rotate(180deg);
  }
}

.border {
  border: 1px solid var(--color-gray-805);
}

.gl-counter {
  margin-left: 8px;
  padding: 4px 8px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background: var(--color-gray-735);
  border-radius: 8px;
  font-family: 'SF Pro', serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: var(--color-gray-890);

  &.client {
    background: var(--color-gray-455);
  }

  &--black {
    color: var(--primary-text-color);
  }
}

.gl-user-displaying {
  &__wrapper {
    display: flex;
    align-items: center;
  }

  &__icon {
    display: flex;
    width: 28px;
    height: 28px;
    align-items: center;
    justify-content: center;

    margin-right: 8px;
    border-radius: 50%;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 24px;
    color: var(--color-white-1000);
    box-sizing: border-box;
    flex-shrink: 0;
  }

  &__name {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: var(--primary-text-color);
  }

  &__email {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: var(--color-gray-890);
  }

  &__description {
    flex-shrink: 13;
  }
}

.gl-shared-table--lv {
  & .shared-table {
    &__wrapper {
      height: auto !important;
      max-height: calc(100vh - 302px) !important;
      border: 1px solid var(--border-color);
      border-radius: 8px;
      max-width: var(--content-max-width);
      margin: 0 auto;
      box-shadow: 0 2px 4px -1px rgba(var(--color-rgb-black-840), 0.08);

      & table {
        border-collapse: separate;

        & > tbody > tr:last-child {
          & > td {
            border-bottom: 1px solid transparent !important;
          }
        }
      }
    }

    &__header-cell {
      background: var(--color-gray-735);
      color: var(--color-gray-890);
      position: sticky;
      top: 0;
      border-width: 0 1px 1px 0;
      box-sizing: border-box;
      z-index: 1;
      height: 40px;
      max-height: 40px;
      vertical-align: middle;
      border-right: unset;

      &:first-child {
        padding-left: 13px;
      }
    }

    &__body-cell {
      border-width: 0 1px 1px 0;
      box-sizing: border-box;
      padding: 16px 12px;
      cursor: pointer;
      color: var(--primary-text-color);
      line-height: unset;
      border-right: unset;

      &:first-child {
        padding-left: 16px;
        width: auto;
      }
    }
  }

  &-client .shared-table {
    &__wrapper {
      max-height: calc(100vh - 302px) !important;
    }
  }

  &.client {
    border-radius: 16px;

    .shared-table__wrapper {
      box-shadow: 0 2px 4px -1px rgba(var(--color-gray-730), 0.08);
    }

    .shared-table__header-cell {
      background: var(--color-gray-805);
      color: var(--primary-text-color);
    }

    tr {
      background-color: var(--color-white-1000);
      &.selected {
        background-color: var(--color-black-720);
      }
      &:not(.selected):hover {
        background-color: var(--color-gray-735);
      }
    }
  }
}

.gl-empty-state {
  width: 100%;
  height: 100%;

  &__image {
    width: 64px;
    height: 64px;

    &.w48 {
      width: 48px;
      height: 48px;
    }
  }

  &__title {
    font-family: 'SF Pro', serif;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    text-align: center;
    color: var(--primary-text-color);
    margin: 16px 0;
  }

  &__desc {
    display: inline-block;
    font-family: 'SF Pro', serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: var(--color-gray-890);
  }

  &__image-v3 {
    width: 40px;
    height: 40px;
  }

  &__title-v3 {
    margin: 16px 0 8px;
    font-family: 'SF Pro', serif;
    font-size: 20px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0;
    text-align: left;
    color: var(--primary-text-color);
    @media (max-width: $mobileSize) {
      margin-top: 24px;
    }
  }

  &__desc-v3 {
    font-family: 'SF Pro', serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0;
    text-align: left;
    color: var(--primary-text-color);
  }
}

.gl-empty-search {
  & .not-found__wrapper {
    font-family: 'SF Pro', serif;
    font-style: normal;
    margin-top: -17px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-top: unset !important;
  }
}

.gl-ellipsis-table {
  &-wr {
    display: table;
    table-layout: fixed;
    width: 100%;
    line-height: 24px;

    &.disabled {
      color: var(--color-gray-890);
    }
  }

  &-item {
    display: table-cell;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    line-height: 24px;
  }
}

.client-empty {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: 'SF Pro', serif;

  &__image {
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--color-blue-915);
    border: 4px solid var(--color-white-1000);
    box-shadow: 0 4px 12px rgba(var(--color-gray-730), 0.08);
    border-radius: 8px;
  }

  &__title {
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    color: var(--primary-text-color);
    margin: 24px 0 8px;
  }

  &__message {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: var(--color-gray-890);
  }
}

.image-mark {
  &__img-wrapper {
    position: relative;
    width: fit-content;
    height: 100%;
    margin: 0 auto;

    img {
      width: auto;
      height: 100%;
      display: block;
    }
  }

  &__mark {
    width: 32px;
    height: 32px;
    box-sizing: border-box;
    position: absolute;
    border: 1px solid var(--color-gray-805);
    border-radius: 50% 50% 50% 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--color-white-1000);

    span {
      width: 24px;
      height: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: var(--color-blue-980);
      border-radius: 12px;
      font-weight: 500;
      font-size: 12px;
      line-height: 16px;
      color: var(--color-white-1000);
    }
  }
}

.position-relative {
  position: relative !important;
}

.client-empty {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: 'SF Pro', serif;

  &__image {
    width: 48px;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--color-blue-915);
    border: 4px solid var(--color-white-1000);
    box-shadow: 0 4px 12px rgba(var(--color-gray-730), 0.08);
    border-radius: 8px;
  }

  &__title {
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    color: var(--primary-text-color);
    margin: 24px 0 8px;
  }

  &__message {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: var(--color-gray-890);
  }
}

.cdk-overlay-dark-backdrop {
  opacity: 0.01 !important;
}

.table-items-counter {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  background: var(--color-gray-735);
  width: 35px;
  height: 24px;
  color: var(--color-gray-890);
  font-size: 14px;
}

.client-highlighted {
  z-index: 1051 !important;
  background: var(--color-white-1000);
  pointer-events: none;

  &.options-catalogue__purchase {
    background: var(--color-black-960);
  }

  &.options-catalogue__help {
    background: unset;
    .dropdown__toggle-value {
      background: rgba(var(--color-rgb-white-1000), 0.16);
    }
  }
}

.client-highlighted__mobile {
  z-index: 1051 !important;
  background: var(--color-white-1000);
  pointer-events: none;

  &::after {
    content: '';
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: -300!important;
    bottom: 0!important;
    left: 0!important;
    right: 0!important;
    background-color: rgba(var(--color-rgb-black-1000), .48);
    display: block;
    z-index: 2;
  }

  .left-menu__menu {
    .left-menu__item {
      &:first-of-type {
        z-index: 3;
      }
    }
  }

  &:not(.step-1) {
    .drop-down__title {
      background-color: var(--color-white-1000);
      pointer-events: none;
      border-radius: 8px;
      position: relative;
      z-index: 3;
    }
    .apps_dropdown--mobile {
      z-index: 3;
      position: relative !important;
    }
  }

  &.left-menu__profile {
    .left-menu__profile-wrapper {
      z-index: 3;
    }
  }
}

.dropdown-menu[aria-labelledby="more-dropdown"] {
  min-width: 280px;
  max-width: 300px;
  display: none;
  border-radius: 4px;
  border: 1px solid var(--color-gray-805);
  background: var(--color-white-1000);
  box-shadow: 0 16px 24px 0 rgba(var(--color-gray-730), 0.08);
  box-sizing: border-box;
  margin-top: 8px;
  z-index: 100;
  white-space: nowrap;

  &.client {
    border-radius: 12px;
  }

  &.show {
    display: block;
  }

  atl-firm-icon {
    .user {
      width: 24px;
      height: 24px;
    }
  }

  .more-dropdown {
    &__item {
      width: 100% !important;
      height: 32px !important;
      box-sizing: border-box;
      padding: 0 8px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-radius: 4px !important;
      cursor: pointer;
      margin: 0 !important;

      &.disabled {
        cursor: not-allowed;
        &:hover {
          background-color: transparent;
        }
      }

      &:hover {
        background: var(--color-gray-735) !important;
      }

      &:not(:last-child) {
        margin-bottom: 8px !important;
      }

      &.footer {
        margin: 0;
        color: var(--color-red-830);
      }

      div:first-child {
        img {
          margin-right: 8px;
        }
      }
    }
  }
}

.options-dropdown .dropdown-menu[aria-labelledby="more-dropdown"] {
  width: 100%;
  min-width: 229px;
  max-width: 229px;
}

.reclamation {
  &__status {
    display: flex !important;
    width: fit-content !important;
    font-weight: 400;
    font-size: 14px !important;
    line-height: 24px !important;
    padding: 0 8px;
    border-radius: 16px;
    align-items: center;

    &-indicator {
      height: 8px;
      width: 8px;
      border-radius: 50%;
      margin-right: 8px;
      flex-shrink: 0;
    }

    &-on-approval {
      background: var(--color-gray-735);
      .reclamation__status-indicator {
        background: var(--color-gray-890);
      }
    }

    &-draft {
      background: var(--color-gray-735);
      color: var(--primary-text-color);
      .reclamation__status-indicator {
        background: var(--color-gray-890);
      }
    }

    &-in_progress {
      background: var(--color-gray-815);
      color: var(--color-black-966);
      .reclamation__status-indicator {
        background: var(--color-blue-980);
      }
    }

    &-fixed {
      background: var(--color-green-200);
      color: var(--color-gray-897);
      .reclamation__status-indicator {
        background: var(--color-green-897);
      }
    }

    &-internal_review {
      background: var(--color-violet-900);
      color: var(--color-black-966);
      .reclamation__status-indicator {
        background: var(--color-blue-989);
      }
    }

    &-declined-client {
      background: var(--color-red-500);
      .reclamation__status-indicator {
        background: var(--color-red-780);
      }
    }

    &-disputed {
      background: var(--color-red-499);
      color: var(--color-black-985);
      .reclamation__status-indicator {
        background: var(--color-red-829);
      }
    }

    &-declined {
      background: var(--color-red-498);
      color: var(--color-black-985);
      .reclamation__status-indicator {
        background: var(--color-red-734);
      }
    }

    &-review {
      background: var(--color-violet-100);
      color: var(--color-black-985);
      .reclamation__status-indicator {
        background: var(--color-violet-1000);
      }
    }

    &-pending {
      background: var(--color-yellow-600);
      color: var(--color-yellow-2000);
      .reclamation__status-indicator {
        background: var(--color-yellow-999);
      }
    }

    &-waiting_for_customer {
      background: var(--color-green-793);
      color: var(--color-gray-897);
      .reclamation__status-indicator {
        background: var(--color-green-250);
      }
    }

    &-waiting_for_contractor {
      background: var(--color-red-739);
      color: var(--color-yellow-2000);
      .reclamation__status-indicator {
        background: var(--color-red-741);
      }
    }

    &-one_year_inspection {
      background: var(--color-blue-010);
      color: var(--color-black-966);
      .reclamation__status-indicator {
        background: var(--color-blue-020);
      }
    }
  }
}

.dropdown-fdv {
  .dropdown-menu {
    width: 180px;
    border-radius: 4px;
    border: 1px solid var(--color-gray-805);
    background: var(--color-white-1000);
    box-shadow: 0 16px 24px 0 rgba(var(--color-gray-730), 0.08);
  }
}

.fdv-folder-menu {
  .dropdown-menu {
    width: 280px;
    border-radius: 4px;
    border: 1px solid var(--color-gray-805);
    background: var(--color-white-1000);
    box-shadow: 0 16px 24px 0 rgba(var(--color-gray-730), 0.08);
  }
}

.zoom-menu {
  .dropdown-menu {
    width: 123px;
    border-radius: 4px;
    border: 1px solid var(--color-gray-805);
    background: var(--color-white-1000);
    box-shadow: 0 16px 24px 0 rgba(var(--color-gray-730), 0.08);
    transform: translate(-10px, 10px);
  }
}

.filter-black-960 {
  filter: var(--filter-black-960) !important;
}

.single-time {

  & > .ngb-dp-header {
    display: none;
  }

  .picker__time img {
    display: none;
    right: 8px !important;
    top: 8px !important;
  }

  .picker__data-picker.ng-dirty.ng-invalid {
    .picker__time img {
      display: block;
    }

    .picker__time-single {
      border: 1px solid var(--color-red-830);
    }
  }
}

.coming-soon {
  color: var(--color-blue-980);
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  padding: 4px 8px;
  border-radius: 4px;
  background: var(--color-gray-815);
  white-space: nowrap;
}

.more-button-toggle {
  display: flex;
  border-radius: 8px;
  background: var(--color-gray-735);
  color: var(--color-gray-890);
  height: 32px;
  padding: 2px;
  box-sizing: border-box;
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  cursor: pointer;
  margin-right: 12px;

  div {
    border-radius: 6px;
    padding: 6px 16px;

    &.active, &:hover {
      background: var(--color-white-1000);
      color: var(--primary-text-color);
    }

    &:last-child {
      margin-left: 4px;
    }
  }
}
