/* based on angular-toastr css https://github.com/Foxandxss/angular-toastr/blob/cb508fe6801d6b288d3afc525bb40fee1b101650/dist/angular-toastr.css */

@mixin toastIcon {
  content: "";
  display: inline-block;
  width: 16px;
  height: 16px;
  background-size: 16px;
  margin-right: 8px;
}

/* position */
.toast-center-center {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.toast-top-center {
  top: 0;
  right: 0;
  width: 100%;
}
.toast-bottom-center {
  bottom: 10px;
  right: 10px;
  width: calc(100% - 20px);
}
.toast-top-full-width {
  top: 10px;
  right: 0;
  width: 100%;
}
.toast-bottom-full-width {
  bottom: 0;
  right: 0;
  width: 100%;
}
.toast-top-left {
  top: 12px;
  left: 12px;
}
.toast-top-right {
  top: 12px;
  right: 12px;
}
.toast-bottom-right {
  right: 12px;
  bottom: 12px;
}
.toast-bottom-left {
  bottom: 12px;
  left: 12px;
}

/* toast styles */
.toast-title {
  font-weight: bold;
}
.toast-message {
  word-wrap: break-word;
  font-size: 12px;
  line-height: 16px;
}
.toast-title, .toast-message {
  font-family: 'SF Pro', serif;
}
.toast-message a,
.toast-message label {
  color: #FFFFFF;
}
.toast-message a:hover {
  color: #CCCCCC;
  text-decoration: none;
}
.toast-close-button {
  position: absolute;
  right: 20px;
  top: calc(50% - 8px);
  float: right;
  font-size: 20px;
  font-weight: bold;
  color: var(--color-white-1000);
  text-shadow: 0 1px 0 var(--color-white-1000);
  /* opacity: 0.8; */
}
.toast-close-button:hover,
.toast-close-button:focus {
  color: #000000;
  text-decoration: none;
  cursor: pointer;
  opacity: 0.4;
}
.toast-bottom-center .toast-close-button {
  display: none;
}
/*Additional properties for button version
 iOS requires the button element instead of an anchor tag.
 If you want the anchor version, it requires `href="#"`.*/
button.toast-close-button {
  padding: 0;
  cursor: pointer;
  width: 16px;
  height: 16px;
  background-color: unset;
  border: 0;
  background-image: url("/assets/close-white.svg");

  & > span {
    display: none;
  }
}
.toast-container {
  pointer-events: none;
  position: fixed;
  z-index: 999999;
}
.toast-container * {
  box-sizing: border-box;
}
.toast-container .ngx-toastr {
  position: relative;
  overflow: hidden;
  margin: 0 0 6px;
  padding: 8px;
  width: 300px;
  border-radius: 8px;
  background-position: 15px center;
  background-repeat: no-repeat;
  background-size: 24px;
  color: #FFFFFF;
  box-sizing: border-box;
  min-height: 32px;
  max-height: 100px;
  display: flex;
}
.toast-container .ngx-toastr:hover {
  opacity: 0.9;
  cursor: pointer;
}
/* https://github.com/FortAwesome/Font-Awesome-Pro/blob/master/advanced-options/raw-svg/regular/info-circle.svg */
.toast-info {
  &:before {
    @include toastIcon;
    background-image: url("/assets/success-check.svg");
  }
}
/* https://github.com/FortAwesome/Font-Awesome-Pro/blob/master/advanced-options/raw-svg/regular/times-circle.svg */
.toast-error {
  &:before {
    @include toastIcon;
    background-image: url("/assets/warning-circle-white.svg");
  }
}
/* https://github.com/FortAwesome/Font-Awesome-Pro/blob/master/advanced-options/raw-svg/regular/check.svg */
.toast-success {
  &:before {
    @include toastIcon;
    background-image: url("/assets/success-check.svg");
  }
  border: 1px solid rgba(var(--color-rgb-white-1000), 0.16);
}
/* https://github.com/FortAwesome/Font-Awesome-Pro/blob/master/advanced-options/raw-svg/regular/exclamation-triangle.svg */
.toast-warning {
  &:before {
    @include toastIcon;
    background-image: url("/assets/warning-circle-white.svg");
  }
}
.no-icon {
  background-image: none;
  padding-left: 24px !important;
}
.toast-container.toast-top-center .ngx-toastr,
.toast-container.toast-bottom-center .ngx-toastr {
  width: fit-content;
  margin-left: auto;
  margin-right: auto;
}
.toast-container.toast-top-full-width .ngx-toastr,
.toast-container.toast-bottom-full-width .ngx-toastr {
  width: 96%;
  margin-left: auto;
  margin-right: auto;
}
.ngx-toastr {
  background-color: var(--color-blue-1000);
  pointer-events: auto;
}
.toast-success {
  background-color: var(--color-black-960);
}
.toast-error {
  border: 1px solid rgba(var(--color-rgb-white-1000), 0.16);
  background-color: var(--color-red-830);
}
.toast-info {
  background-color: var(--color-blue-1000);
}
.toast-warning {
  background-color: var(--color-yellow-1000);
  color: var(--color-black-1000) !important;

  button.toast-close-button {
    background-image: url("/assets/close.svg");
  }
}
.toast-progress {
  position: absolute;
  left: 0;
  bottom: 0;
  height: 4px;
  background-color: #000000;
  opacity: 0.4;
}
/* Responsive Design */
@media all and (max-width: 240px) {
  .toast-container .ngx-toastr.div {
    padding: 8px 8px 8px 50px;
    width: 11em;
  }
  .toast-container .toast-close-button {
    right: -0.2em;
    top: -0.2em;
  }
}
@media all and (min-width: 241px) and (max-width: 480px) {
  .toast-container .ngx-toastr.div {
    padding: 8px 8px 8px 50px;
    width: 18em;
  }
  .toast-container .toast-close-button {
    right: 0.8em;
    top: 0.8em;
  }
}
@media all and (min-width: 481px) and (max-width: 768px) {
  .toast-container .ngx-toastr.div {
    padding: 15px 15px 15px 50px;
    width: 25em;
  }
}
