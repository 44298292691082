$mobileSize: 768px;

.card {
  background-color: var(--color-white-1000);
  border-radius: 12px;
  font-family: 'SF Pro', serif;
  font-weight: 400;
  z-index: 2;

  &:not(:empty) {
    border: 1px solid var(--border-color);
  }

  &--specific {
    background-color: var(--color-white-1000);
    border-radius: 12px;
  }

  &__title {
    border-bottom: 1px solid var(--color-gray-750);
  }

  &__content {
    padding: 24px;
  }

  &__footer {
    padding: var(--pad-1);

    &__bordered {
      border-top: 1px solid var(--color-gray-450)
    }

    &__fill-gray {
      background: var(--color-gray-650);
    }

    &__fill-green {
      background: var(--color-green-1000);
    }
  }

  &__info {
    margin-left: 12px;
    flex-grow: 1;
    align-self: center;
  }

  &__sub {
    margin-top: 5px;
    display: flex;
    justify-content: space-between;
  }

  &__inf-banner {
    padding: 4px 0;
    margin-right: 28px;
    min-width: 81px;
    background-color: var(--color-black-1000);
    border-radius: 4px;
    text-align: center;
    font-family: 'SF Pro', serif;
    color: var(--color-white-1000);
    z-index: 2;

    &:after {
      position: absolute;
      content: '';
      width: 12px;
      height: 12px;
      background-color: var(--color-black-1000);
      top: calc(50% - 6px);
      right: -2px;
      transform: rotate(45deg);
      border-radius: 2px;
      z-index: -1;
    }
  }

  @media (max-width: $mobileSize) {
    & {
      border: 1px solid transparent;
    }
  }

  @media (max-width: 576px) {
    & {
      width: 100%;
      margin-bottom: 5px;
    }
  }
}

.external-product-card {
  display: flex;
  padding: 16px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
  border-radius: 12px;
  cursor: pointer;
  &:hover {
    background: var(--color-gray-735);
  }

  &--active {
    background: var(--color-gray-815);
  }

}

.custom-product-image {
  width: 160px;
  height: 100px;
  filter: var(--filter-gray-902);
}

.custom-product-status {
  border-radius: 40px;
  background: var(--color-white-1000);
  display: flex;
  width: auto;
  padding: 8px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px; 
  white-space: nowrap;
  
  &--danger {
    color: var(--color-red-830);
  }

  &--success {
    color: var(--color-green-910);
  }

}