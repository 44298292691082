$mobileSize: 768px;

.shared-table {
  &__header-cell {
    padding: 12px;
    border: 1px solid var(--color-gray-820);
    text-align: left;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.02em;
    text-transform: uppercase;
    color: var(--color-gray-890);
    z-index: 2;
    border-right: unset;

    @media (max-width: $mobileSize) {
      &:first-child {
        border-right: 1px solid var(--color-gray-820);
      }
    }

    & > .sort-header-container > button.sort-header-button {
      text-transform: uppercase;
    }

    &:nth-child(1) {
      padding: 12px 12px 12px 1px;
      border-left: none;
    }

    &:last-child {
      border-right: 1px solid transparent;
    }

    &.w100 {
      width: 100%;
    }

    &.w81 {
      width: 81%;
    }

    &.w80 {
      width: 80%;
    }

    &.w78 {
      width: 78%;
    }

    &.w76 {
      width: 76%;
    }

    &.w74 {
      width: 74%;
    }

    &.w70 {
      width: 70%;
    }

    &.w69 {
      width: 69%;
    }

    &.w66 {
      width: 66%;
    }

    &.w65 {
      width: 65%;
    }

    &.w62 {
      width: 62%;
    }

    &.w60 {
      width: 60%;
    }

    &.w56 {
      width: 56%;
    }

    &.w55 {
      width: 55%;
    }

    &.w50 {
      width: 50%;
    }

    &.w49 {
      width: 49%;
    }

    &.w48 {
      width: 48%;
    }

    &.w47 {
      width: 47%;
    }

    &.w46 {
      width: 46%;
    }

    &.w45 {
      width: 45%;
    }

    &.w44 {
      width: 44%;
    }

    &.w42 {
      width: 42%;
    }

    &.w40 {
      width: 40%;
    }

    &.w39 {
      width: 39%;
    }

    &.w38 {
      width: 38%;
    }

    &.w36 {
      width: 36%;
    }

    &.w35 {
      width: 35%;
    }

    &.w34 {
      width: 34%;
    }

    &.w33 {
      width: 33%;
    }

    &.w32 {
      width: 32%;
    }

    &.w31 {
      width: 31%;
    }

    &.w30 {
      width: 30%;
    }

    &.w29 {
      width: 29%;
    }

    &.w28 {
      width: 28%;
    }

    &.w26 {
      width: 26%;
    }

    &.w25 {
      width: 25%;
    }

    &.w24 {
      width: 24%;
    }

    &.w23 {
      width: 23%;
    }

    &.w22 {
      width: 22%;
    }

    &.w21 {
      width: 21%;
    }

    &.w20 {
      width: 20%;
    }

    &.w19 {
      width: 19%;
    }

    &.w18 {
      width: 18%;
    }

    &.w17 {
      width: 17%;
    }

    &.w16 {
      width: 16%;
    }

    &.w15 {
      width: 15%;
    }

    &.w14 {
      width: 14%;
    }

    &.w13 {
      width: 13%;
    }

    &.w12 {
      width: 12%;
    }

    &.w11 {
      width: 11%;
    }

    &.w10 {
      width: 10%;
    }

    &.w9 {
      width: 9%;
    }

    &.w8 {
      width: 8%;
    }

    &.w7 {
      width: 7%;
    }

    &.w6 {
      width: 6%;
    }

    &.w5 {
      width: 5%;
    }
  }

  &__body-cell {
    padding: 10px 12px;
    vertical-align: middle;
    border: 1px solid var(--border-color);
    text-align: left;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: var(--primary-text-color);
    border-right: unset;

    @media (max-width: $mobileSize) {
      &:first-child {
        border-right: 1px solid var(--color-gray-820);
      }
    }

    &--dropdown-wr {
      position: relative;

      &:hover {
        background-color: var(--color-gray-740);

        &::after {
          top: 14px;
          right: 22px;
          width: 12px;
          height: 12px;
          display: block;
          position: absolute;
          content: "";
          background-image: url('/assets/newest_table_dropdown.svg');
          background-size: cover;
        }
      }

      &--active {
        background-color: var(--color-gray-740);

        &::after {
          top: 14px;
          right: 22px;
          width: 12px;
          height: 12px;
          display: block;
          position: absolute;
          content: "";
          background-image: url('/assets/newest_table_dropdown.svg');
          background-size: cover;
          transform: rotate(180deg);
        }
      }
    }

    &:nth-child(1) {
      padding: 12px 12px 12px 0;
      border-left: none;
    }

    &:last-child {
      border-right: 1px solid transparent;
    }

    &.w100 {
      width: 100%;
    }

    &.w81 {
      width: 81%;
    }

    &.w80 {
      width: 80%;
    }

    &.w78 {
      width: 78%;
    }

    &.w76 {
      width: 76%;
    }

    &.w74 {
      width: 74%;
    }

    &.w70 {
      width: 70%;
    }

    &.w69 {
      width: 69%;
    }

    &.w66 {
      width: 66%;
    }

    &.w65 {
      width: 65%;
    }

    &.w62 {
      width: 62%;
    }

    &.w60 {
      width: 60%;
    }

    &.w56 {
      width: 56%;
    }

    &.w55 {
      width: 55%;
    }

    &.w50 {
      width: 50%;
    }

    &.w49 {
      width: 49%;
    }

    &.w48 {
      width: 48%;
    }

    &.w47 {
      width: 47%;
    }

    &.w46 {
      width: 46%;
    }

    &.w45 {
      width: 45%;
    }

    &.w44 {
      width: 44%;
    }

    &.w42 {
      width: 42%;
    }

    &.w40 {
      width: 40%;
    }

    &.w39 {
      width: 39%;
    }

    &.w38 {
      width: 38%;
    }

    &.w36 {
      width: 36%;
    }

    &.w35 {
      width: 35%;
    }

    &.w34 {
      width: 34%;
    }

    &.w33 {
      width: 33%;
    }

    &.w32 {
      width: 32%;
    }

    &.w31 {
      width: 31%;
    }

    &.w30 {
      width: 30%;
    }

    &.w29 {
      width: 29%;
    }

    &.w28 {
      width: 28%;
    }

    &.w26 {
      width: 26%;
    }

    &.w25 {
      width: 25%;
    }

    &.w24 {
      width: 24%;
    }

    &.w22 {
      width: 22%;
    }

    &.w21 {
      width: 21%;
    }

    &.w20 {
      width: 20%;
    }

    &.w19 {
      width: 19%;
    }

    &.w18 {
      width: 18%;
    }

    &.w17 {
      width: 17%;
    }

    &.w16 {
      width: 16%;
    }

    &.w15 {
      width: 15%;
    }

    &.w14 {
      width: 14%;
    }

    &.w13 {
      width: 13%;
    }

    &.w12 {
      width: 12%;
    }

    &.w11 {
      width: 11%;
    }

    &.w10 {
      width: 10%;
    }

    &.w9 {
      width: 9%;
    }

    &.w8 {
      width: 8%;
    }

    &.w7 {
      width: 7%;
    }

    &.w6 {
      width: 6%;
    }

    &.w5 {
      width: 5%;
    }
  }

  &__dropdown {
    &-wrapper {
      top: 45px;
      left: 0;
      position: absolute;
      width: 173px;
      background-color: var(--color-white-1000);
      border: 1px solid var(--color-black-740);
      filter: drop-shadow(0px 4px 12px rgba(var(--color-rgb-black-840), 0.08));
      border-radius: 8px;
      z-index: 1;
    }

    &-item {
      padding: 4px;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
      color: var(--color-black-996);
      cursor: pointer;

      & > div {
        padding: 4px 8px;
        border-radius: 8px;
      }

      &:not(:first-child) {
        box-shadow: inset 0 1px 0 var(--color-gray-820);
      }

      &--remove {
        color: var(--color-red-850);
      }

      &:hover {
        & > div {
          background: var(--color-gray-450);
        }
      }
    }
  }
}
