$mobileSize: 744px;
.newest-document-card {
  &-disable-gap {
    height: auto !important;
  }

  &__preview {
    justify-content: unset !important;
  }

  &__item-preview {
    width: fit-content !important;
    min-height: 56px !important;
  }

  &__wrapper {
    position: relative;
    padding: 12px;
    width: 192px;
    height: 56px;
    background-color: var(--color-white-1000);
    border-radius: 8px;
    cursor: pointer;
    border: 1px solid var(--color-gray-820);
    box-sizing: border-box;

    &:hover {
      border: 1px solid var(--color-black-740);
      box-shadow: 0 1px 4px rgba(var(--color-rgb-black-840), 0.08);
    }

    &--active {
      border: 1px solid var(--color-black-740);
      box-shadow: 0 1px 4px rgba(var(--color-rgb-black-840), 0.08);
    }

    &--fluid {
      width: auto;
    }
  }

  &__info {
    display: flex;
    width: 100%;
  }

  &__file-name {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    color: var(--color-black-996);
  }

  &__extension {
    margin-right: 11px;
    width: 32px;
    height: 32px;
  }

  &__file-size {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    color: var(--color-gray-920);
  }

  &__image-container {
    margin-right: 8px;
    width: 32px;
    height: 32px;
    border: 1px solid var(--color-gray-820);
    border-radius: 8px;
    box-sizing: border-box;
  }

  &__icon-control {
    top: 50%;
    transform: translateY(-50%);
    position: absolute;
    display: none;
    width: 24px;
    height: 24px;
    border-radius: 4px;
    justify-content: center;
    align-items: center;
    background-color: var(--color-gray-735);

    &-download {
      right: 12px;

      atl-custom-icon {
        span svg path {
          stroke: var(--color-gray-890);
        }
      }

      &:hover > atl-custom-icon {
        span svg path {
          stroke: var(--color-black-960);
        }
      }
    }

    &-trash {
      right: 39px;
      width: 24px;
      height: 24px;
      border-radius: 4px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: var(--color-gray-735);

      &--specific-position {
        right: 12px;
      }

      &:hover > atl-custom-icon {
        span svg path {
          stroke: var(--color-red-900);
        }
      }
    }
  }

  &__options {
    width: 240px;
    position: absolute;
    right: 12px;
    z-index: 1;
    top: auto;
    bottom: auto;

    &.above-btn {
      bottom: 46px;
    }

    &-btn {
      outline: 5px solid var(--color-white-1000);
    }
    &-panel {
      background: var(--color-white-1000);
      border-radius: 8px;
      gap: 8px;
      padding: 8px;
      border: 1px solid var(--color-gray-805);
    }
    &-item {
      border-radius: 4px;
      padding: 4px 8px;
      display: flex;
      align-items: center;
      gap: 8px;
      font-size: 14px;
      font-weight: 400;
      line-height: 24px;
      color: var(--primary-text-color);
      text-decoration: none;

      &:hover {
        background-color: var(--color-gray-735);
      }

      & img {
        filter: var(--filter-gray-890);
      }
    }
    &-item:not(:first-of-type) {
      margin-top: 8px;
    }
  }
}


.newest-document-card__wrapper:hover .newest-document-card__icon-control,
.newest-document-card__wrapper.has-active-options .newest-document-card__icon-control {
  display: flex;
}

@media (max-width: $mobileSize) {
  .newest-document-card__wrapper .newest-document-card__icon-control,
  .newest-document-card__wrapper.has-active-options .newest-document-card__icon-control {
    display: flex;
  }
}
