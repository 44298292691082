$mobileSize: 743px;

:root {

  --color-black-1000: #000000;
  --color-black-999: #161B25;
  --color-black-998: #041424;
  --color-black-997: #29323F;
  --color-black-996: #171c26;
  --color-black-985: #2B0908;
  --color-black-980: #041A25;
  --color-black-975: #030D21;
  --color-black-971: #040F25;
  --color-black-969: #0000003D;
  --color-black-966: #001133;
  --color-black-965: #467759;
  --color-black-960: #14181F;
  --color-black-959: #14181F3D;
  --color-black-950: #102438;
  --color-black-925: #04363D;
  --color-black-900: #0F2438;
  --color-gray-897: #0B281A;
  --color-gray-893: #171C2633;
  --color-gray-892: #14181F14;
  --color-black-850: #657380;
  --color-black-830: #777D88;
  --color-black-800: #798490;
  --color-black-750: #9fa7af;
  --color-black-745: #A7AFBE;
  --color-black-740: #ced5da;
  --color-black-730: #F4F4F6;
  --color-black-720: #F1F2F4;
  --color-black-700: #F5F6F7;
  --color-black-650: #FCFCFD;
  --color-black-600: #f5f5f5;
  --color-black-500: #CED2DA;
  --color-black-333: #333;

  --color-gray-1000: #595959;
  --color-gray-960: #EBEEF029;
  --color-gray-950: #667380;
  --color-gray-930: #656E7B;
  --color-gray-920: #727883;
  --color-gray-915: #A2AAB9;
  --color-gray-910: #9196A1;
  --color-gray-905: #A6A6A6;
  --color-gray-903: #8B96A5;
  --color-gray-902: #8F96A3;
  --color-gray-900: #707781;
  --color-gray-899: #A2B0B9;
  --color-gray-898: #E1F4E8;
  --color-gray-896: #171C2614;

  --color-gray-895: #f4f4f4;
  --color-gray-894: #EBECF0;
  --color-gray-853: #E8EAEE;
  --color-gray-890: #686E79;
  --color-gray-888: #EBF1FF;
  --color-gray-885: #e8ecee;
  --color-gray-875: #CFD3D7;
  --color-gray-850: #CDD4DA;
  --color-gray-830: #9EA3AE;
  --color-gray-824: #DDDEDF;
  --color-gray-823: #f4f2f1;
  --color-gray-822: #f9f8f8;
  --color-gray-820: #ebeef0;
  --color-gray-815: #E5EEFF;
  --color-gray-810: #F0F5FF;
  --color-gray-807: #C2C9D6;
  --color-gray-806: #A3AFC2;
  --color-gray-805: #EAECF0;
  --color-gray-800: #c4c4c4;
  --color-gray-775: #efefef;
  --color-gray-760: #F6F8F9;
  --color-gray-755: #E6E6E6;
  --color-gray-750: #e7e9eb;
  --color-gray-745: #545A65;
  --color-gray-740: #faf8f8;
  --color-gray-735: #F7F7F8;
  --color-gray-730: 20, 24, 31;
  --color-gray-725: 19, 24, 32;
  --color-gray-720: 34, 0, 77;
  --color-gray-675: #0F243814;
  --color-gray-650: #f3f4f5;
  --color-gray-550: #f9fafa;
  --color-gray-525: #C4C9D4;
  --color-gray-500: #ECF2FF;
  --color-gray-475: #9ea7af;
  --color-gray-470: #c8cbcf;
  --color-gray-455: #E2E4E9;
  --color-gray-450: #F4F8FF;
  --color-gray-440: #F5F8FF;
  --color-gray-200: #F6F7F9;
  --color-gray-100: #FFF7E1;

  --color-white-1000: #ffffff;

  --color-green-1100: #22774D;
  --color-green-1000: #05944f;
  --color-green-910: #2E9E66;
  --color-green-900: #0aae5f;
  --color-green-897: #39C680;
  --color-green-895: #5CB27D;
  --color-green-794: #5DA277;
  --color-green-793: #AECF5429;
  --color-green-890: #46e2bd;
  --color-green-850: #6da085;
  --color-green-750: #8FC1A2;
  --color-green-250: #AECF54;
  --color-green-200: #EBF9F2;
  --color-green-100: #E1F2EA;
  --color-green-50: #EAFAF0;

  --color-red-1100: #AD231F;
  --color-red-1000: #ec1c1c;
  --color-red-950: #F0ABA8;
  --color-red-900: #e11900;
  --color-red-850: #e01a00;
  --color-red-830: #D92C26;
  --color-red-829: #E33A82;
  --color-red-810: #DD3731;
  --color-red-780: #E05652;
  --color-red-750: #ed7465;
  --color-red-745: #E8817D;
  --color-red-734: #DF5E53;
  --color-red-741: #EF8D43;
  --color-red-740: #EB8C6F;
  --color-red-739: #FDF1E8;
  --color-red-650: #FFD8D6;
  --color-red-600: #FFF0F0;
  --color-red-500: #FBEAE9;
  --color-red-499: #FDECF3;
  --color-red-498: #FCF0EF;

  --color-yellow-2000: #332200;
  --color-yellow-1300: #320;
  --color-yellow-1250: #3A2000;
  --color-yellow-1200: #DD9F22;
  --color-yellow-1150: #ffa000;
  --color-yellow-1100: #FFAA00;
  --color-yellow-1000: #FFC043;
  --color-yellow-999: #FFBB33;
  --color-yellow-995: #FFC042;
  --color-yellow-990: #F1BD6A;
  --color-yellow-900: #e7d74e;
  --color-yellow-800: #FFD786;
  --color-yellow-700: #FFEECC;
  --color-yellow-600: #FFF7E5;

  --color-purple-800: #4141D8;
  --color-purple-600: #6262EA;

  --color-blue-1300: #013;
  --color-blue-1000: #2c6bfd;
  --color-blue-995: #04c;
  --color-blue-991: #1A46B3;
  --color-blue-990: #0044CC;
  --color-blue-989: #5933FF;
  --color-blue-982: #05F;
  --color-blue-980: #0055FF;
  --color-blue-970: #0059FF;
  --color-blue-960: #457EFE;
  --color-blue-950: #2665f6;
  --color-blue-935: #004BE0;
  --color-blue-930: #2B6AFD;
  --color-blue-925: #3377FF;
  --color-blue-920: #246DFF;
  --color-blue-915: #4FB1F8;
  --color-blue-911: #6699FF;
  --color-blue-910: #80AAFF;
  --color-blue-860: #99bbff;
  --color-blue-850: #99BBFF;
  --color-blue-100: #CCDDFF;
  --color-blue-060: #E4EEFF;
  --color-blue-055: #EFF5FF;
  --color-blue-050: #E0EEFF;
  --color-blue-020: #1192BB;
  --color-blue-010: #EDF8F9;

  --color-violet-1000: #9924FF;
  --color-violet-900: #E7E5FFE5;
  --color-violet-100: #F5EAFF;

  --color-rgb-yellow-1000: 255, 192, 66;

  --color-rgb-blue-900: 65, 122, 255;
  --color-rgb-blue-850: 44, 107, 253;
  --color-rgb-blue-800: 0, 85, 255;
  --color-rgb-blue-500: 239, 245, 255;

  --color-rgb-red-1000: 225, 25, 0;
  --color-rgb-red-800: 217, 44, 38;

  --color-rgb-black-1000: 0, 0, 0;
  --color-rgb-black-950: 18, 18, 18;
  --color-rgb-black-900: 15, 36, 56;
  --color-rgb-black-850: 5, 36, 56;
  --color-rgb-black-840: 23, 28, 38;
  --color-rgb-black-830: 22, 27, 37;
  --color-rgb-black-820: 51, 51, 51;
  --color-rgb-black-800: 159, 167, 175;
  --color-rgb-black-700: 231, 233, 235;
  --color-rgb-black-660: 228, 229, 231;
  --color-rgb-black-650: 234, 236, 240;
  --color-rgb-black-600: 245, 246, 247;
  --color-rgb-black-400: 249, 249, 249;
  --color-rgb-black-350: 247, 247, 248;
  --color-rgb-black-250: 232, 232, 232;
  --color-rgb-black-200: 199, 199, 199;

  --color-rgb-white-1000: 255, 255, 255;

    // ToDO: Nobel colors -quick solution day before demo.
    --nobel-color-header: #1A171B;
    --nobel-button-main: #BA7879;
    --nobel-color-light: #F4E9E9;
    --nobel-button-hover: #702935;
    --nobel-placeholder-color: #666666;
    --novel-border-color: #F4E9EA;
    --nobel-active-state: #E0E0E0;
    --nobel-color-gray: #5D5D5D;
    --nobel-btn-background: #f7f5f6;
    --nobel-color-lightgray: #D9D9D9;


  --modal-top-position: 64px;
  --client-empty-state-height: calc(100vh - 168px);
  --client-mobile-empty-state-height: calc(100vh - 104px);

  --pad-0-5: 8px;
  --pad-1: 16px;
  --pad-1-5: 24px;
  --pad-2: 32px;
  --pad-2-5: 40px;
  --pad-3: 48px;
  --pad-3-5: 56px;


  --admin-empty-card-height: calc(100vh - 210px);
  --admin-table-full-height: calc(100vh - 352px);
  --admin-table-height: calc(100vh - 312px);
  --admin-table-height-custom: calc(100vh - 368px);
  --admin-table-full-height-custom: calc(100vh - 423px);
  --admin-table-full-height-units: calc(100vh - 283px);
  --admin-table-with-header-height: calc(100vh - 210px);
  --admin-table-products-full-height: calc(100vh - 251px);
  --admin-table-full-height-messages: calc(100vh - 200px);
  --admin-table-full-height-project-messages: calc(100vh - 245px);
  --admin-option-builder-header-height: 72px;
  --admin-page-without-header: calc(100vh - 128px);
  --admin-page-options: calc(100vh - 166px);
  --admin-page-option-units: calc(100vh - 280px);
  --admin-oprion-store-modal-content: calc(100vh - 192px);
  --admin-oprion-store-rooms-content: calc(100vh - 352px);
  --admin-oprion-store-units-content: calc(100vh - 296px);
  --client-contact: calc(100vh - 185px);
  --admin-unit-type-template-modal-content: calc(100vh - 160px);
  --admin-sidebar-width: 72px;
  --admin-sidebar-expand-width: 240px;
  --admin-mobile-sidebar-height-header: 64px;
  --admin-projects-list-height: calc(100vh - 80px);
  --admin-timeline-height-content: calc(100vh - 287px);
  --admin-table-without-tabs: calc(100vh - 232px);
  --admin-table-with-tabs: calc(100vh - 272px);
  --admin-table-product-list-height: calc(100vh - 128px);
  --admin-table-product-list-library-height: calc(100vh - 267px);
  --admin-back-button-offset-of-unit-modal: 66px;

  --admin-profile-settings-content: calc(var(--viewport-height) - 132px);

  @supports (height: 100svh) {
    --viewport-height: 100svh;
  }

  @supports not (height: 100svh) {
    --viewport-height: calc(var(--vh, 1vh) * 100);
  }

  --client-height-header: 72px;
  --client-height-layout: calc(var(--viewport-height) - var(--client-height-header));

  --admin-min-table-width: 750px;

  --content-max-width: 1440px;
  --content-settings-max-width: 1008px;

  --border-radius: 8px;

  //#FFFFFF
  --filter-white: brightness(0) saturate(100%) invert(100%) sepia(100%) saturate(0%) hue-rotate(248deg) brightness(102%) contrast(103%);

  //#14181F
  --filter-black-960: brightness(0) saturate(100%) invert(7%) sepia(19%) saturate(793%) hue-rotate(180deg) brightness(99%) contrast(96%);

  //#777D88
  --filter-black-830: brightness(0) saturate(100%) invert(51%) sepia(17%) saturate(218%) hue-rotate(180deg) brightness(92%) contrast(93%);

  //#CDD4DA;
  --filter-gray-850: brightness(0) saturate(100%) invert(92%) sepia(8%) saturate(195%) hue-rotate(166deg) brightness(95%) contrast(82%);

  //#686E79
  --filter-gray-890: brightness(0) saturate(100%) invert(45%) sepia(7%) saturate(650%) hue-rotate(181deg) brightness(91%) contrast(87%);

  //#8F96A3
  --filter-gray-902: brightness(0) saturate(100%) invert(64%) sepia(7%) saturate(496%) hue-rotate(181deg) brightness(92%) contrast(89%);

  //#C4C9D4;
  --filter-gray-525: brightness(0) saturate(100%) invert(99%) sepia(2%) saturate(6108%) hue-rotate(179deg) brightness(87%) contrast(91%);

  //#D92C26
  --filter-red-830: brightness(0) saturate(100%) invert(16%) sepia(98%) saturate(2891%) hue-rotate(351deg) brightness(109%) contrast(87%);

  //#AD231F;
  --filter-red-1100: brightness(0) saturate(100%) invert(16%) sepia(86%) saturate(2607%) hue-rotate(348deg) brightness(96%) contrast(93%);

  //#1A46B3
  --filter-blue-991: brightness(0) saturate(100%) invert(18%) sepia(98%) saturate(2442%) hue-rotate(220deg) brightness(84%) contrast(92%);

  //#0055FF
  --filter-blue-980: brightness(0) saturate(100%) invert(22%) sepia(100%) saturate(4562%) hue-rotate(219deg) brightness(101%) contrast(109%);

  //#0044CC
  --filter-blue-990: brightness(0) saturate(100%) invert(16%) sepia(77%) saturate(4026%) hue-rotate(219deg) brightness(95%) contrast(102%);

  //#99BBFF
  --filter-blue-850: brightness(0) saturate(100%) invert(64%) sepia(77%) saturate(415%) hue-rotate(191deg) brightness(101%) contrast(101%);

  // Related color: #727883;
  --filter-color-gray: brightness(0) saturate(100%) invert(51%) sepia(15%) saturate(299%) hue-rotate(180deg) brightness(88%) contrast(81%);

  //#FFAA00
  --filter-yellow-1100: brightness(0) saturate(100%) invert(61%) sepia(83%) saturate(1035%) hue-rotate(360deg) brightness(102%) contrast(105%);

  //#05F
  --filter-blue-982: brightness(0) saturate(100%) invert(19%) sepia(85%) saturate(4554%) hue-rotate(220deg) brightness(102%) contrast(108%);

  //#595959
  --filter-gray-1000: brightness(0) saturate(100%) invert(35%) sepia(0%) saturate(725%) hue-rotate(134deg) brightness(92%) contrast(85%);

  //#FBEAE9
  --filter-red-500: brightness(0) saturate(100%) invert(94%) sepia(3%) saturate(1275%) hue-rotate(314deg) brightness(101%) contrast(97%);

  //#2E9E66
  --filter-green-910: brightness(0) saturate(100%) invert(44%) sepia(69%) saturate(391%) hue-rotate(97deg) brightness(100%) contrast(94%);

  @media (max-width: $mobileSize) {
    --admin-page-without-header: calc(100vh - 128px - var(--admin-mobile-sidebar-height-header));
    --admin-profile-settings-content: calc(var(--viewport-height) - 132px - var(--admin-mobile-sidebar-height-header));
    --admin-projects-list-height: calc(100vh - 64px - var(--admin-mobile-sidebar-height-header));
    --admin-table-full-height-units: calc(100vh - 277px - var(--admin-mobile-sidebar-height-header));
    --admin-timeline-height-content: calc(100vh - 287px - var(--admin-mobile-sidebar-height-header));
    --admin-table-full-height: calc(100vh - 352px - var(--admin-mobile-sidebar-height-header));
    --admin-table-without-tabs: calc(100vh - 232px - var(--admin-mobile-sidebar-height-header));
    --admin-table-with-tabs: calc(100vh - 272px - var(--admin-mobile-sidebar-height-header));
    --admin-table-product-list-height: calc(100vh - 128px - var(--admin-mobile-sidebar-height-header));
  }

  // White label variable

  --primary-text-color: var(--color-black-960);
  --accent-color: var(--color-gray-815);
  --button-border-radius: 32px;
  --button-text-color: var(--color-blue-980);
  --background-layout: var(--color-gray-735);
  --border-color: var(--color-gray-805);
  --border-color-transparent: var(transparent);
}

// Reusable colour classes
.text {

  &-black-985 {
    color: var(--color-black-985);
  }

  &-black-900 {
    color: var(--color-black-900);
  }

  &-black-850 {
    color: var(--color-black-850);
  }

  &-black-960 {
    color: var(--primary-text-color);
  }

  &-black-999 {
    color: var(--color-black-999);
  }

  &-black-996 {
    color: var(--color-black-996);
  }

  &-black-900 {
    color: var(--color-black-900);
  }

  &-black-750 {
    color: var(--color-black-750);
  }

  &-black-810 {
    color: var(--color-gray-890);
  }

  &-black-333 {
    color: var(--color-black-333);
  }

  &-blue-1000 {
    color: var(--color-blue-1000);
  }

  &-blue-955 {
    color: var(--color-blue-920);
  }

  &-blue-980 {
    color: var(--color-blue-980);
  }

  &-blue-982 {
    color: var(--color-blue-982);
  }

  &-gray-900 {
    color: var(--color-gray-900);
  }

  &-gray-902 {
    color: var(--color-gray-902);
  }

  &-gray-930 {
    color: var(--color-gray-930);
  }

  &-gray-920 {
    color: var(--color-gray-920);
  }

  &-gray-525 {
    color: var(--color-gray-525);
  }

  &-gray-485 {
    color: var(--color-black-830) !important;
  }

  &-gray-890 {
    color: var(--color-gray-890) !important;
  }

  &-red-830 {
    color: var(--color-red-830);
  }

  &-white-1000 {
    color: var(--color-white-1000);
  }
}

.filter-icon {
  &-gray-890 {
    filter: var(--filter-gray-890);
  }

  &-gray-902 {
    filter: var(--filter-gray-902);
  }

  &-black-960 {
    filter: var(--filter-black-960);
  }

  &-blue-980 {
    filter: var(--filter-blue-980);
  }
}


.bg {
  &-white-1000 {
    background-color: var(--color-white-1000)!important;
  }
}
